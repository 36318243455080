import { Box } from "@mui/material";
import { styles } from "../../../styles";

const Information = () => {
	return (
		<Box sx={{ ...styles.centerFlex }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 5 5"
				fill="none"
			>
				<g id="Group">
					<path
						id="Vector"
						d="M2.79004 4.18002C3.86699 4.18002 4.73999 3.30698 4.73999 2.23003C4.73999 1.15307 3.86699 0.280029 2.79004 0.280029C1.71308 0.280029 0.840088 1.15307 0.840088 2.23003C0.840088 3.30698 1.71308 4.18002 2.79004 4.18002Z"
						stroke="black"
						strokeWidth="0.25"
						strokeMiterlimit="10"
					/>
					<g id="Group_2">
						<g id="Group_3">
							<path
								id="Vector_2"
								d="M2.73999 1.52013C2.67999 1.52013 2.63009 1.50013 2.59009 1.46013C2.55009 1.42013 2.53003 1.37013 2.53003 1.32013C2.53003 1.26013 2.55009 1.22011 2.59009 1.18011C2.63009 1.14011 2.67999 1.12012 2.73999 1.12012C2.79999 1.12012 2.84989 1.14011 2.88989 1.18011C2.92989 1.22011 2.94995 1.27013 2.94995 1.32013C2.94995 1.38013 2.92989 1.42013 2.88989 1.46013C2.84989 1.50013 2.79999 1.52013 2.73999 1.52013ZM2.57007 3.50012V1.78012H2.8999V3.50012H2.57007Z"
								fill="black"
							/>
						</g>
					</g>
				</g>
			</svg>
		</Box>
	);
};

export default Information;
