import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
const ShopOpenLeftArrowBlack = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="51"
        height="35"
        viewBox="0 0 51 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.75 34.25L0 17.5L16.75 0.75L20.6583 4.65833L10.6083 14.7083H50.25V20.2917H10.6083L20.6583 30.3417L16.75 34.25Z"
          fill="black"
        />
      </svg>
    </Box>
  );
};

export default ShopOpenLeftArrowBlack;
