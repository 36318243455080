import React from "react";
import { Box } from "@mui/material";
import { styles } from "../../../styles";

const GpsIcons = () => {
  return (
    <Box sx={{ ...styles.centerFlex, justifyContent: "center" }}>
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 10C19.5 17 10.5 23 10.5 23C10.5 23 1.5 17 1.5 10C1.5 7.61305 2.44821 5.32387 4.13604 3.63604C5.82387 1.94821 8.11305 1 10.5 1C12.8869 1 15.1761 1.94821 16.864 3.63604C18.5518 5.32387 19.5 7.61305 19.5 10Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.5 13C12.1569 13 13.5 11.6569 13.5 10C13.5 8.34315 12.1569 7 10.5 7C8.84315 7 7.5 8.34315 7.5 10C7.5 11.6569 8.84315 13 10.5 13Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

export default GpsIcons;
