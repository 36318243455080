import { Box } from "@mui/material";
import { styles } from "../../../styles";
import React from "react";

const FacebookIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.643 9.73745C21.643 4.36572 16.8036 0.0201416 10.8215 0.0201416C4.83947 0.0201416 0 4.36572 0 9.73745C0 14.5861 3.96568 18.6099 9.14119 19.3342V12.534H6.38531V9.71732H9.14119V7.5846C9.14119 5.15022 10.7542 3.80225 13.2412 3.80225C14.4286 3.80225 15.6609 3.98349 15.6609 3.98349V6.37752H14.2943C12.95 6.37752 12.5242 7.12192 12.5242 7.88643V9.71732H15.5265L15.0559 12.534H12.5242V19.3342C17.6997 18.6099 21.643 14.5861 21.643 9.73745Z"
          fill="black"
        />
        <path
          d="M15.0335 12.5542L15.504 9.73749H12.5019V7.9066C12.5019 7.14209 12.9275 6.39769 14.2718 6.39769H15.6385V4.00366C15.6385 4.00366 14.4062 3.82242 13.2187 3.82242C10.7542 3.82242 9.11873 5.17039 9.11873 7.60477V9.73749H6.36292V12.5542H9.11873V19.3544C9.67885 19.4348 10.239 19.4749 10.8215 19.4749C11.404 19.4749 11.964 19.4348 12.5242 19.3544V12.5542H15.0335Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

export default FacebookIcon;
