import { Box } from "@mui/material";
import React from "react";

const MenuSearchIconBlack = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.20603 14.4544C10.6336 14.4544 13.4122 11.535 13.4122 7.93384C13.4122 4.33268 10.6336 1.41345 7.20603 1.41345C3.77848 1.41345 1 4.33268 1 7.93384C1 11.535 3.77848 14.4544 7.20603 14.4544Z"
          stroke="black"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M11.7991 12.8065L15.4062 16.5258"
          stroke="black"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );
};

export default MenuSearchIconBlack;
