import benthuba from "../component/img/trogiupmuasam_benthuba.jpg";
import dientructiep from "../component/img/trogiupmuasam_dientructiep.jpg";
import trogiupmuasamdesktop6 from "../component/img/trogiupmuasamdesktop6.png";

export const sectionsData = [
	{
		title: "Đặt hàng qua ứng dụng của bên thứ ba",
		description: `Chúng tôi mang đến cho bạn trải nghiệm mua sắm thuận tiện và nhanh chóng thông qua ứng dụng của các bên thứ ba. 
      Bạn có thể dễ dàng sử dụng ứng dụng trên điện thoại di động của mình và lựa chọn các sản phẩm mà bạn muốn mua. 
      Sau đó, thực hiện thanh toán trực tuyến một cách nhanh nhất thông qua các phương thức thanh toán an toàn được chúng tôi hỗ trợ.`,
		imgSrc: benthuba,
		imgAlt: "Đặt hàng trực tiếp",
		reverse: true,
	},
	{
		title: "Nhận hàng tại cửa hàng",
		description: `Tiết kiệm thời gian bằng cách đặt hàng trước và thanh toán trên ứng dụng. Sau khi đơn hàng của bạn được đặt, hãy vào trong, đi đến khu vực chờ và chúng tôi sẽ gọi tên bạn khi sẵn sàng. Đội ngũ nhân viên của chúng tôi sẽ hỗ trợ bạn trong quá trình mua sắm và đảm bảo bạn có được sản phẩm mong muốn. 
      Kiểm tra xem bạn có thể ghé thăm địa điểm Nhận hàng của The Thanh Coffee gần bạn không.`,
		imgSrc: dientructiep,
		imgAlt: "Đặt hàng trực tiếp",
		reverse: false,
	},
	{
		title: "Gọi điện trực tiếp qua số Hotline",
		description: `Nếu bạn có bất kỳ thắc mắc nào hoặc muốn được tư vấn trực tiếp, bạn có thể gọi điện trực tiếp đến chúng tôi để đặt hàng. 
      Đội ngũ chăm sóc khách hàng của chúng tôi sẽ sẵn lòng hỗ trợ bạn và ghi lại đơn hàng của bạn một cách chi tiết và chính xác.`,
		imgSrc: dientructiep,
		imgAlt: "Đặt hàng trực tiếp",
		reverse: true,
		buttonText: "Gọi ngay",
		buttonLink: "tel:19001009",
	},
	{
		title: "Nhắn tin cho Chúng tôi",
		description: `Chúng tôi cũng cung cấp dịch vụ đặt hàng thông qua tin nhắn. Bạn có thể gửi tin nhắn cho chúng tôi với các sản phẩm mà bạn muốn mua hoặc yêu cầu thêm thông tin. 
      Chúng tôi sẽ liên hệ lại với bạn để xác nhận đơn hàng và hỗ trợ bạn trong quá trình thanh toán và giao hàng.`,
		imgSrc: trogiupmuasamdesktop6,
		imgAlt: "Đặt hàng trực tiếp",
		reverse: false,
		buttonText: "Nhắn tin ngay",
		buttonLink: "tel:19001009",
	},
];
