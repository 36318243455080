import { Box } from "@mui/material";
import React from "react";

const ArrowRightBlack = () => {
  return (
    <Box>
      <svg
        width="22"
        height="10"
        viewBox="0 0 22 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.0918 4.87048H21.15"
          stroke="#231F20"
          strokeWidth="0.8"
          strokeMiterlimit="10"
        />
        <path
          d="M7.12857 9.52207C7.25701 9.28833 7.19266 9.00775 7 8.84413L1.28461 4.87053L7 0.897064C7.21407 0.75682 7.25701 0.452867 7.12857 0.219127C7.00013 -0.0146126 6.72176 -0.0613549 6.50769 0.078889L0.214275 4.44981C0.0858355 4.54331 0 4.68366 0 4.84727C0 5.01089 0.0858355 5.17447 0.214275 5.24459L6.50769 9.61551C6.57191 9.66226 6.65777 9.68556 6.74339 9.68556C6.89324 9.73231 7.04294 9.66231 7.12857 9.52207Z"
          fill="#231F20"
        />
      </svg>
    </Box>
  );
};

export default ArrowRightBlack;
