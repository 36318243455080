import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
const PlayCircleIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="47"
        height="47"
        viewBox="0 0 47 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3538 32.3752L32.3749 23.5L18.3538 14.6248V32.3752ZM23.5274 46.7917C20.3248 46.7917 17.3075 46.1859 14.4755 44.9744C11.6439 43.7629 9.16447 42.0945 7.03707 39.9694C4.90967 37.8442 3.23976 35.3679 2.02733 32.5404C0.814448 29.7134 0.208008 26.6927 0.208008 23.4783C0.208008 20.2635 0.813771 17.2514 2.0253 14.442C3.23683 11.633 4.90516 9.16479 7.0303 7.0374C9.15544 4.91 11.6318 3.24009 14.4593 2.02766C17.2863 0.814776 20.307 0.208336 23.5213 0.208336C26.7361 0.208336 29.7482 0.814102 32.5577 2.02563C35.3667 3.23716 37.8349 4.90549 39.9623 7.03063C42.0897 9.15577 43.7596 11.6267 44.972 14.4433C46.1849 17.26 46.7913 20.2696 46.7913 23.4722C46.7913 26.6748 46.1856 29.6922 44.974 32.5242C43.7625 35.3557 42.0942 37.8352 39.969 39.9626C37.8439 42.09 35.373 43.7599 32.5563 44.9723C29.7397 46.1852 26.73 46.7917 23.5274 46.7917ZM23.4997 44.625C29.3677 44.625 34.3556 42.5712 38.4632 38.4635C42.5709 34.3559 44.6247 29.3681 44.6247 23.5C44.6247 17.6319 42.5709 12.6441 38.4632 8.53646C34.3556 4.42882 29.3677 2.375 23.4997 2.375C17.6316 2.375 12.6438 4.42882 8.53613 8.53646C4.42849 12.6441 2.37467 17.6319 2.37467 23.5C2.37467 29.3681 4.42849 34.3559 8.53613 38.4635C12.6438 42.5712 17.6316 44.625 23.4997 44.625Z"
          fill="#1C1B1F"
        />
      </svg>
    </Box>
  );
};

export default PlayCircleIcon;
