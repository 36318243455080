import { Box } from "@mui/material";
import React from "react";

const BlackLogoSmall = () => {
  return (
    <Box>
      <svg
        width="114"
        height="21"
        viewBox="0 0 114 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M47.8159 2.45597V1.90909H51.6341V2.45597H50.0332V7H49.4167V2.45597H47.8159ZM52.5855 7V1.90909H53.202V4.17614H55.9165V1.90909H56.5329V7H55.9165V4.72301H53.202V7H52.5855ZM57.7671 7V1.90909H60.8396V2.45597H58.3836V4.17614H60.6805V4.72301H58.3836V6.45312H60.8794V7H57.7671Z"
          fill="black"
        />
        <path
          d="M8.58107 11.6703V13.984H8.33458C8.17775 13.4408 8.02091 13.0584 7.84167 12.8371C7.66243 12.5957 7.41599 12.4148 7.07992 12.2739C6.90068 12.1935 6.58705 12.1531 6.13896 12.1531H5.42199V18.7521C5.42199 19.1948 5.44441 19.4564 5.51162 19.5771C5.55643 19.6777 5.66842 19.7781 5.82526 19.8585C5.98209 19.939 6.20616 19.9794 6.47501 19.9794H6.78864V20.2208H1.79242V19.9794H2.10605C2.37491 19.9794 2.599 19.939 2.77824 19.8384C2.89026 19.778 3.00223 19.6776 3.06944 19.5368C3.11425 19.4362 3.13672 19.1746 3.13672 18.732V12.133H2.44211C1.79237 12.133 1.32188 12.2538 1.05303 12.4952C0.649741 12.8372 0.38087 13.32 0.268846 13.9437H0V11.63L8.58107 11.6703Z"
          fill="black"
        />
        <path
          d="M13.0398 16.1165V18.7723C13.0398 19.2149 13.0622 19.4763 13.1294 19.597C13.1743 19.6976 13.2862 19.7982 13.4431 19.8787C13.5999 19.9592 13.824 19.9995 14.0704 19.9995H14.3841V20.2409H9.38782V19.9995H9.70152C9.97037 19.9995 10.1944 19.9591 10.3736 19.8585C10.4857 19.7982 10.5977 19.6978 10.6649 19.557C10.7097 19.4564 10.7321 19.1948 10.7321 18.7521V13.1188C10.7321 12.6762 10.7097 12.4145 10.6649 12.2938C10.6201 12.1932 10.508 12.0929 10.3512 12.0124C10.1944 11.9319 9.97033 11.8916 9.72388 11.8916H9.41024V11.6501H14.4065V11.8916H14.0928C13.824 11.8916 13.6 11.9319 13.4207 12.0325C13.3087 12.0929 13.1967 12.1933 13.1294 12.3341C13.0846 12.4347 13.0622 12.6963 13.0622 13.1389V15.5533H16.423V13.1389C16.423 12.6963 16.4005 12.4347 16.3557 12.314C16.3109 12.2134 16.1989 12.113 16.042 12.0325C15.8852 11.952 15.6611 11.9117 15.3923 11.9117H15.0786V11.6703H20.0525V11.9117H19.7388C19.4476 11.9117 19.2235 11.9518 19.0667 12.0524C18.9547 12.1128 18.8426 12.2134 18.7754 12.3542C18.7306 12.4548 18.7082 12.7164 18.7082 13.1591V18.7924C18.7082 19.235 18.7306 19.4964 18.7754 19.6171C18.8202 19.7177 18.9323 19.8183 19.0891 19.8988C19.246 19.9793 19.47 20.0196 19.7388 20.0196H20.0525V20.2611H15.0786V20.0196H15.3923C15.6835 20.0196 15.9076 19.9793 16.0645 19.8787C16.1765 19.8183 16.2885 19.7179 16.3557 19.5771C16.4005 19.4765 16.423 19.2149 16.423 18.7723V16.1165H13.0398Z"
          fill="black"
        />
        <path
          d="M26.3032 17.7865H22.9425L22.5392 18.6112C22.4047 18.8928 22.3375 19.1143 22.3375 19.2954C22.3375 19.5368 22.4496 19.7179 22.6512 19.8185C22.7857 19.8789 23.0993 19.9391 23.5922 19.9592V20.2006H20.4108V19.9592C20.7468 19.919 21.0381 19.7783 21.2621 19.5771C21.4862 19.3759 21.755 18.9332 22.0687 18.2894L25.4742 11.449H25.6087L29.059 18.4704C29.395 19.1344 29.6639 19.5569 29.8656 19.7178C30.0224 19.8385 30.2464 19.919 30.5377 19.9391V20.1805H25.9223V19.9391H26.1016C26.4825 19.9391 26.7289 19.8989 26.8857 19.7984C26.9977 19.738 27.0425 19.6374 27.0425 19.5167C27.0425 19.4362 27.0202 19.3557 26.9978 19.2752C26.9978 19.235 26.9081 19.0741 26.7961 18.8126L26.3032 17.7865ZM26.0567 17.3235L24.6452 14.3661L23.1889 17.3235H26.0567Z"
          fill="black"
        />
        <path
          d="M34.3016 11.6703L39.0067 16.9817V13.2998C39.0067 12.7767 38.9171 12.4349 38.7602 12.2538C38.5362 12.0124 38.1553 11.8916 37.64 11.8916V11.6501H40.7991V11.8916C40.3958 11.9318 40.1269 11.9921 39.9925 12.0726C39.858 12.153 39.746 12.2739 39.6788 12.4348C39.6116 12.5958 39.5668 12.8773 39.5668 13.2998V20.4219H39.3204L32.8678 13.2998V18.7521C32.8678 19.235 33.0022 19.577 33.2486 19.7581C33.4951 19.9392 33.7863 20.0196 34.1 20.0196H34.3241V20.2611H30.9409V20.0196C31.4562 20.0196 31.8372 19.919 32.0388 19.738C32.2404 19.5569 32.3524 19.235 32.3524 18.7723V12.7366L32.1508 12.5154C31.9492 12.294 31.7699 12.1329 31.6131 12.0726C31.4563 11.9921 31.2322 11.952 30.9409 11.952V11.7106H34.3016V11.6703Z"
          fill="black"
        />
        <path
          d="M45.056 16.1165V18.7723C45.056 19.2149 45.0785 19.4763 45.1457 19.597C45.1905 19.6976 45.3025 19.7982 45.4593 19.8787C45.6161 19.9592 45.8402 19.9995 46.0866 19.9995H46.4003V20.2409H41.4041V19.9995H41.7177C41.9865 19.9995 42.2106 19.9591 42.3899 19.8585C42.5019 19.7982 42.6139 19.6978 42.6811 19.557C42.726 19.4564 42.7484 19.1948 42.7484 18.7521V13.1188C42.7484 12.6762 42.726 12.4145 42.6811 12.2938C42.6363 12.1932 42.5243 12.0929 42.3674 12.0124C42.2106 11.9319 41.9866 11.8916 41.7401 11.8916H41.4265V11.6501H46.4227V11.8916H46.1091C45.8402 11.8916 45.6161 11.9319 45.4369 12.0325C45.3249 12.0929 45.2129 12.1933 45.1457 12.3341C45.1009 12.4347 45.0784 12.6963 45.0784 13.1389V15.5533H48.4391V13.1389C48.4391 12.6963 48.4167 12.4347 48.3719 12.314C48.3271 12.2134 48.2151 12.113 48.0583 12.0325C47.9014 11.952 47.6774 11.9117 47.4085 11.9117H47.0949V11.6703H52.0687V11.9117H51.755C51.4638 11.9117 51.2398 11.9518 51.0829 12.0524C50.9709 12.1128 50.8589 12.2134 50.7917 12.3542C50.7469 12.4548 50.7244 12.7164 50.7244 13.1591V18.7924C50.7244 19.235 50.7469 19.4964 50.7917 19.6171C50.8365 19.7177 50.9485 19.8183 51.1053 19.8988C51.2621 19.9793 51.4862 20.0196 51.755 20.0196H52.0687V20.2611H47.0949V20.0196H47.4085C47.6998 20.0196 47.9239 19.9793 48.0807 19.8787C48.1927 19.8183 48.3047 19.7179 48.3719 19.5771C48.4167 19.4765 48.4391 19.2149 48.4391 18.7723V16.1165H45.056Z"
          fill="black"
        />
        <path
          d="M65.5341 11.4691V14.4467H65.2653C65.0636 13.6822 64.6827 13.0786 64.1226 12.6762C63.5625 12.2738 62.9128 12.0524 62.2182 12.0524C61.6133 12.0524 61.0756 12.2136 60.5827 12.5154C60.0898 12.8171 59.7313 13.2195 59.4849 13.7224C59.1936 14.3662 59.0367 15.0703 59.0367 15.8348C59.0367 16.5993 59.1488 17.3035 59.3504 17.9272C59.5745 18.5509 59.9105 19.0337 60.3586 19.3556C60.8067 19.6775 61.4117 19.8384 62.151 19.8384C62.756 19.8384 63.2937 19.7178 63.809 19.4764C64.3019 19.235 64.8396 18.8326 65.3773 18.2492V18.9936C64.8396 19.4965 64.2795 19.8588 63.697 20.0801C63.1144 20.3014 62.4423 20.4219 61.6581 20.4219C60.6499 20.4219 59.7313 20.2408 58.9471 19.8787C58.1629 19.5165 57.5581 18.9936 57.11 18.3096C56.6843 17.6256 56.4602 16.9011 56.4602 16.1165C56.4602 15.2916 56.7067 14.527 57.1772 13.8027C57.6477 13.0785 58.2974 12.4951 59.104 12.0927C59.9105 11.6903 60.7619 11.4893 61.6805 11.4893C62.3526 11.4893 63.0696 11.6099 63.809 11.8714C64.2347 12.0122 64.5259 12.0927 64.6379 12.0927C64.7948 12.0927 64.9292 12.0526 65.0188 11.952C65.1085 11.8514 65.198 11.6905 65.2428 11.4893L65.5341 11.4691Z"
          fill="black"
        />
        <path
          d="M71.8745 11.5495C73.398 11.5092 74.6302 11.8917 75.5712 12.7366C76.5122 13.5816 77.0051 14.6479 77.0051 15.9153C77.0051 17.0018 76.6467 17.9675 75.9521 18.7723C75.0111 19.8587 73.6892 20.4219 71.9865 20.4219C70.2613 20.4219 68.9394 19.8988 67.9984 18.8526C67.2591 18.0277 66.8782 17.042 66.8782 15.9153C66.8782 14.6479 67.3711 13.5816 68.3345 12.7366C69.2979 11.8917 70.463 11.4891 71.8745 11.5495ZM71.964 11.952C71.0902 11.952 70.4405 12.3543 69.97 13.1591C69.5892 13.823 69.4099 14.7686 69.4099 15.9959C69.4099 17.4646 69.7012 18.5308 70.2613 19.235C70.6646 19.7178 71.2247 19.9592 71.9417 19.9592C72.4346 19.9592 72.8379 19.8586 73.1516 19.6373C73.5548 19.3757 73.8909 18.9331 74.1149 18.3496C74.339 17.7662 74.451 16.9818 74.451 16.0362C74.451 14.9095 74.339 14.0645 74.0925 13.5012C73.8685 12.9378 73.5548 12.5353 73.1963 12.314C72.8603 12.0524 72.4345 11.952 71.964 11.952Z"
          fill="black"
        />
        <path
          d="M81.4861 12.1531V15.6739H81.7551C82.1808 15.6739 82.5168 15.6136 82.7857 15.513C83.0545 15.3923 83.2785 15.2112 83.4578 14.9295C83.637 14.668 83.7715 14.2856 83.8163 13.843H84.0628V18.1485H83.8163C83.7266 17.3437 83.4802 16.8007 83.0993 16.5391C82.696 16.2776 82.248 16.1567 81.7551 16.1567H81.4861V18.7723C81.4861 19.2149 81.5086 19.4763 81.5758 19.597C81.6206 19.6976 81.7327 19.7982 81.8895 19.8787C82.0463 19.9592 82.2704 19.9995 82.5168 19.9995H82.8305V20.2409H77.8342V19.9995H78.1479C78.4167 19.9995 78.6408 19.9591 78.82 19.8585C78.9321 19.7982 79.044 19.6978 79.1112 19.557C79.1561 19.4564 79.1785 19.1948 79.1785 18.7521V13.1188C79.1785 12.6762 79.1561 12.4145 79.1112 12.2938C79.0664 12.1932 78.9545 12.0929 78.7976 12.0124C78.6408 11.9319 78.4167 11.8916 78.1703 11.8916H77.8566V11.6501H85.8999V14.1046H85.6087C85.5415 13.5211 85.3846 13.0986 85.0934 12.8169C84.8245 12.5353 84.4436 12.3343 83.9283 12.2337C83.6371 12.1733 83.1217 12.133 82.36 12.133L81.4861 12.1531Z"
          fill="black"
        />
        <path
          d="M90.2689 12.1531V15.6739H90.5378C90.9635 15.6739 91.2995 15.6136 91.5684 15.513C91.8372 15.3923 92.0613 15.2112 92.2406 14.9295C92.4198 14.668 92.5542 14.2856 92.599 13.843H92.8455V18.1485H92.599C92.5094 17.3437 92.263 16.8007 91.8821 16.5391C91.4788 16.2776 91.0307 16.1567 90.5378 16.1567H90.2689V18.7723C90.2689 19.2149 90.2914 19.4763 90.3586 19.597C90.4034 19.6976 90.5154 19.7982 90.6722 19.8787C90.829 19.9592 91.0531 19.9995 91.2995 19.9995H91.6132V20.2409H86.6169V19.9995H86.9306C87.1995 19.9995 87.4235 19.9591 87.6028 19.8585C87.7148 19.7982 87.8268 19.6978 87.894 19.557C87.9388 19.4564 87.9612 19.1948 87.9612 18.7521V13.1188C87.9612 12.6762 87.9388 12.4145 87.894 12.2938C87.8492 12.1932 87.7372 12.0929 87.5803 12.0124C87.4235 11.9319 87.1995 11.8916 86.953 11.8916H86.6394V11.6501H94.6827V14.1046H94.3914C94.3242 13.5211 94.1674 13.0986 93.8761 12.8169C93.6073 12.5353 93.2264 12.3343 92.711 12.2337C92.4198 12.1733 91.9045 12.133 91.1428 12.133L90.2689 12.1531Z"
          fill="black"
        />
        <path
          d="M99.0291 12.1531V15.6336H99.2083C99.8132 15.6336 100.261 15.4727 100.53 15.1307C100.799 14.7886 100.978 14.2857 101.045 13.6217H101.314V18.1082H101.045C101.001 17.6254 100.866 17.223 100.687 16.9011C100.508 16.5993 100.306 16.3782 100.06 16.2776C99.8132 16.177 99.4772 16.1165 99.0291 16.1165V18.5309C99.0291 18.9936 99.0515 19.2952 99.0963 19.3958C99.1411 19.4964 99.2307 19.597 99.3427 19.6574C99.4547 19.7178 99.6564 19.7581 99.9252 19.7581H100.485C101.382 19.7581 102.098 19.577 102.636 19.1947C103.174 18.8326 103.555 18.2693 103.801 17.5048H104.07L103.644 20.2208H95.3995V19.9794H95.7131C95.982 19.9794 96.2061 19.939 96.3853 19.8384C96.4973 19.778 96.6094 19.6776 96.6766 19.5368C96.7214 19.4362 96.7438 19.1746 96.7438 18.732V13.0986C96.7438 12.7164 96.7214 12.475 96.6989 12.3945C96.6541 12.2537 96.5645 12.1329 96.4301 12.0524C96.2509 11.9317 96.0044 11.8714 95.6908 11.8714H95.3771V11.63H103.353V14.165H103.084C102.95 13.5413 102.748 13.0986 102.502 12.8371C102.255 12.5755 101.919 12.3745 101.449 12.2538C101.18 12.1733 100.687 12.133 99.97 12.133H99.0291V12.1531Z"
          fill="black"
        />
        <path
          d="M108.641 12.1531V15.6336H108.82C109.425 15.6336 109.873 15.4727 110.142 15.1307C110.411 14.7886 110.59 14.2857 110.657 13.6217H110.926V18.1082H110.657C110.612 17.6254 110.478 17.223 110.299 16.9011C110.119 16.5993 109.918 16.3782 109.671 16.2776C109.425 16.177 109.089 16.1165 108.641 16.1165V18.5309C108.641 18.9936 108.663 19.2952 108.708 19.3958C108.753 19.4964 108.842 19.597 108.954 19.6574C109.066 19.7178 109.268 19.7581 109.537 19.7581H110.097C110.993 19.7581 111.71 19.577 112.248 19.1947C112.786 18.8326 113.166 18.2693 113.413 17.5048H113.682L113.256 20.2208H105.011V19.9794H105.325C105.594 19.9794 105.818 19.939 105.997 19.8384C106.109 19.778 106.221 19.6776 106.288 19.5368C106.333 19.4362 106.355 19.1746 106.355 18.732V13.0986C106.355 12.7164 106.333 12.475 106.311 12.3945C106.266 12.2537 106.176 12.1329 106.042 12.0524C105.863 11.9317 105.616 11.8714 105.302 11.8714H104.989V11.63H112.965V14.165H112.696C112.562 13.5413 112.36 13.0986 112.113 12.8371C111.867 12.5755 111.531 12.3745 111.06 12.2538C110.792 12.1733 110.299 12.133 109.582 12.133H108.641V12.1531Z"
          fill="black"
        />
      </svg>
    </Box>
  );
};

export default BlackLogoSmall;
