import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
const NewsMatchCaseIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.617188 19.1778L7.66625 -0.00485229H9.71094L16.8683 19.1778H14.7842L12.8478 13.6395H4.54016L2.62906 19.1778H0.617188ZM5.18563 11.9375H12.2277L8.75891 2.14437H8.64359L5.18563 11.9375ZM23.2686 19.6105C21.8189 19.6105 20.6555 19.2109 19.7783 18.4119C18.9008 17.6125 18.462 16.6058 18.462 15.3917C18.462 14.0648 18.9831 12.9981 20.0253 12.1916C21.0672 11.3853 22.4078 10.9822 24.0472 10.9822C24.7181 10.9822 25.3973 11.0506 26.0848 11.1875C26.7723 11.3244 27.3781 11.5142 27.9022 11.757V10.6541C27.9022 9.43531 27.5938 8.50859 26.9769 7.8739C26.3603 7.23921 25.4608 6.92187 24.2783 6.92187C23.6317 6.92187 23.0295 7.03421 22.4717 7.2589C21.9139 7.48359 21.3563 7.82921 20.7988 8.29577L19.6053 7.23218C20.3072 6.59031 21.0325 6.12265 21.7812 5.82921C22.53 5.53609 23.3659 5.38952 24.2891 5.38952C26.0847 5.38952 27.4177 5.84921 28.288 6.76859C29.158 7.68796 29.593 9.06718 29.593 10.9062V19.1778H27.9022V17.2634H27.7147C27.2291 18.0541 26.6172 18.643 25.8791 19.0301C25.1413 19.417 24.2711 19.6105 23.2686 19.6105ZM23.3984 18.0889C24.8287 18.0889 25.9388 17.6298 26.7284 16.7117C27.5181 15.7933 27.913 14.6417 27.913 13.257C27.4755 13.007 26.9219 12.8117 26.2522 12.6711C25.5828 12.5305 24.9272 12.4601 24.2853 12.4601C23.045 12.4601 22.0594 12.715 21.3284 13.2247C20.5978 13.7344 20.2325 14.4567 20.2325 15.3917C20.2325 16.1611 20.5245 16.803 21.1086 17.3173C21.6927 17.8317 22.4559 18.0889 23.3984 18.0889Z"
          fill="#1C1B1F"
        />
      </svg>
    </Box>
  );
};

export default NewsMatchCaseIcon;
