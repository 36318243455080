import { Box } from "@mui/material";
import { styles } from "../../../styles";
import React from "react";

const ArrowBack = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.825 9L9.425 14.6L8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825Z"
          fill="#1C1B1F"
        />
      </svg>
    </Box>
  );
};

export default ArrowBack;
