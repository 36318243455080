import React, { useState } from "react";
import { styles } from "../../styles.js";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

const NavbarOrder = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { text: "Thông điệp gửi gắm", href: "/thong-diep-gui-gam" },
    { text: "Nhiệm vụ giá trị", href: "/nhiem-vu-va-gia-tri" },
    { text: "Môi trường làm việc", href: "/moi-truong-lam-viec" },
    { text: "Văn hóa tuân thủ", href: "/van-hoa-tuan-thu" },
    { text: "Trách nhiệm", href: "/trach-nhiem" },
    { text: "Tuyển dụng", href: "/tham-gia-the-thanh" },
    { text: "Hỗ trợ", href: "/ho-tro" },
    { text: "Lợi ích & đặc quyền", href: "/loi-ich-va-dac-quyen" },
  ];

  const drawer = (
    <Box
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        {menuItems.map((item) => (
          <ListItemButton
            component="a"
            href={item.href}
            key={item.text}
            sx={{
              "&:hover": {
                color: "#dad8d4",
                textDecoration: "none",
              },
            }}
          >
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      {isMobile ? (
        <Box
          sx={{
            ...styles.fontSize13,
            position: "fixed",
            backgroundColor: "white",
            zIndex: 10,
            width: "100%",
            height: "95px",
            display: "flex",
            alignItems: "center",
            mt: "50px",
            justifyContent: "space-between",
            borderBottom: "1px solid #bebebe",
            boxSizing: "border-box",
          }}
        >
          <a href="/gia-tri-va-van-hoa" rel="Trang chủ">
            <Box
              sx={{
                p: "auto",
                boxSizing: "border-box",
                textAlign: "center",
                px: 2,
              }}
            >
              <Box>THE</Box>
              <Box sx={{ fontWeight: "bold", fontSize: "20px" }}>
                THANH COFFEE
              </Box>
              <Box>CA PHE TOT & TRA NGON</Box>
            </Box>
          </a>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
            {drawer}
          </Drawer>
        </Box>
      ) : (
        <Box
          sx={{
            ...styles.fontSize13,
            position: "fixed",
            backgroundColor: "#006241",
            fontFamily: "Inter",
            zIndex: 10,
            width: "100%",
            height: "100px",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #bebebe",
            justifyContent: "space-around",
          }}
        >
          <div
            id="navbar-desktop"
            style={{
              display: "flex",
              fontSize: "16px",
              boxSizing: "border-box",
              textTransform: "uppercase",
              color: "white",
            }}
          >
            <a href="/thong-diep-gui-gam">
              <Box sx={{ pr: "20px" }}>Menu</Box>{" "}
            </a>
            <a href="/nhiem-vu-va-gia-tri">
              <Box sx={{ pr: "20px" }}>Nhượng quyền</Box>
            </a>
            <a href="/moi-truong-lam-viec">
              <Box sx={{ pr: "20px" }}>Dịch vụ ăn uống</Box>
            </a>
            <a href="/van-hoa-tuan-thu">
              <Box sx={{ pr: "20px" }}>Thanh shop</Box>
            </a>
            <div style={{ position: "relative" }}>
              <Box
                onClick={toggleDropdown}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Box sx={{ pr: "6.5px" }}>Xem thêm</Box>
                <svg
                  width="12"
                  height="6"
                  viewBox="0 0 12 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 0.75L6 5.25L10.5 0.75"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              {isDropdownOpen && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "150%",
                    right: 0,
                    backgroundColor: "#006241",
                    boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                    zIndex: 20,
                    width: "max-content",
                    border: "1px solid #D7D7D7",
                    borderRadius: "8px",
                  }}
                >
                  <a href="/loi-ich-va-dac-quyen#quyen-loi-va-dang-ky">
                    <Box sx={{ p: "8px 16px" }}>The Thanh tài chính</Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#suc-khoe-va-cuoc-song">
                    <Box sx={{ p: "8px 16px" }}>Thanh Shop</Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#dac-quyen">
                    <Box sx={{ p: "8px 16px" }}>The Thanh chuyển tiếp</Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box sx={{ p: "8px 16px" }}>Thẻ quà tặng The Thanh</Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box
                      sx={{ p: "8px 16px", borderBottom: "2px solid #D7D7D7" }}
                    >
                      Nhượng quyền
                    </Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box sx={{ p: "8px 16px" }}>Các câu hỏi thường gặp</Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box sx={{ p: "8px 16px" }}>
                      Câu hỏi thường gặp về ứng dụng di động
                    </Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box sx={{ p: "8px 16px" }}>Định vị cửa hàng</Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box sx={{ p: "8px 16px" }}>Khảo sát và phản hồi</Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box
                      sx={{ p: "8px 16px", borderBottom: "2px solid #D7D7D7" }}
                    >
                      Liên hệ chúng tôi
                    </Box>
                  </a>
                  <a href="/nhuong-quyen/cau-chuyen-cua-chung-toi">
                    <Box sx={{ p: "8px 16px" }}>
                      Câu chuyện cà phê của chúng tôi
                    </Box>
                  </a>
                  <a href="/loi-ich-va-dac-quyen#qua-tang">
                    <Box sx={{ p: "8px 16px" }}>
                      Dinh dưỡng và chất gây dị ứng
                    </Box>
                  </a>
                </Box>
              )}
            </div>
          </div>
          <Box
            sx={{
              ...styles.centerFlex,
              textAlign: "center",
              alignItems: "center",
              height: "50px",
            }}
          >
            <a href="/">
              <svg
                width="234"
                height="50"
                viewBox="0 0 234 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M102.594 4.61364V3.36364H111.322V4.61364H107.663V15H106.253V4.61364H102.594ZM113.496 15V3.36364H114.905V8.54545H121.11V3.36364H122.519V15H121.11V9.79545H114.905V15H113.496ZM125.34 15V3.36364H132.363V4.61364H126.749V8.54545H131.999V9.79545H126.749V13.75H132.454V15H125.34Z"
                  fill="white"
                />
                <path
                  d="M18.5128 28.2363V33.8842H17.9937C17.6825 32.6107 17.3716 31.6693 16.9567 31.0603C16.5937 30.5066 16.0748 30.0637 15.4006 29.7314C15.0375 29.5653 14.4146 29.4546 13.4811 29.4546H12.03V45.5118C12.03 46.5638 12.0808 47.2285 12.1845 47.5054C12.2882 47.7822 12.4963 48.0037 12.8075 48.2252C13.1186 48.4467 13.5854 48.502 14.104 48.502H14.7257V49.0555H4.50901V48.502H5.13196C5.70245 48.502 6.16926 48.3913 6.48043 48.1698C6.73974 48.0037 6.94648 47.7822 7.05021 47.4499C7.15393 47.2285 7.20595 46.5638 7.20595 45.5118V29.4546H5.80556C4.50901 29.4546 3.52464 29.7313 2.95415 30.3404C2.12436 31.171 1.60548 32.3338 1.39803 33.8842H0.878906V28.2363H18.5128Z"
                  fill="white"
                />
                <path
                  d="M27.641 39.0336V45.4567C27.641 46.5087 27.6917 47.1731 27.7955 47.4499C27.8992 47.7268 28.1073 47.9483 28.4184 48.1698C28.7296 48.3913 29.1964 48.4466 29.715 48.4466H30.3367V49.0001H20.1719V48.4466H20.7948C21.3653 48.4466 21.8321 48.3358 22.1433 48.1143C22.4026 47.9482 22.5575 47.7267 22.7131 47.3945C22.8168 47.173 22.8688 46.5087 22.8688 45.4567V31.7801C22.8688 30.7281 22.8168 30.0637 22.7131 29.7869C22.6093 29.51 22.4026 29.2885 22.0914 29.067C21.7802 28.8455 21.3135 28.7902 20.7948 28.7902H20.1719V28.2363H30.3367V28.7902H29.715C29.1445 28.7902 28.6777 28.901 28.3665 29.1224C28.1072 29.2885 27.8992 29.5101 27.7955 29.8423C27.6917 30.0638 27.641 30.7281 27.641 31.7801V37.6493H34.5378V31.7801C34.5378 30.7281 34.4858 30.0637 34.3821 29.7869C34.2784 29.51 34.0716 29.2885 33.7604 29.067C33.4492 28.8455 32.9825 28.7902 32.4638 28.7902H31.8409V28.2363H42.0057V28.7902H41.384C40.8135 28.7902 40.3467 28.901 40.0355 29.1224C39.7762 29.2885 39.5682 29.5101 39.4645 29.8423C39.3607 30.0638 39.31 30.7281 39.31 31.7801V45.4567C39.31 46.5087 39.3607 47.1731 39.4645 47.4499C39.5682 47.7268 39.7763 47.9483 40.0874 48.1698C40.3986 48.3913 40.8654 48.4466 41.384 48.4466H42.0057V49.0001H31.8409V48.4466H32.4638C33.0343 48.4466 33.5011 48.3358 33.8123 48.1143C34.0716 47.9482 34.2784 47.7267 34.3821 47.3945C34.4858 47.173 34.5378 46.5087 34.5378 45.4567V39.0336H27.641Z"
                  fill="white"
                />
                <path
                  d="M54.7121 43.0751H47.814L46.9847 45.1238C46.7253 45.7882 46.5694 46.3422 46.5694 46.7851C46.5694 47.3388 46.7762 47.7817 47.243 48.0585C47.5023 48.2246 48.1252 48.3354 49.1625 48.4462V48.9997H42.6797V48.4462C43.3539 48.3354 43.9766 48.0033 44.3915 47.505C44.8583 47.0066 45.376 45.9546 46.0502 44.3488L53.0002 27.7378H53.2598L60.2604 44.7915C60.9346 46.3973 61.5055 47.4496 61.9204 47.8372C62.2315 48.1694 62.6984 48.3354 63.3208 48.3907V48.9443H53.8815V48.3907H54.2449C55.0228 48.3907 55.5417 48.28 55.8529 48.0585C56.0603 47.8924 56.1644 47.6709 56.1644 47.3387C56.1644 47.1726 56.1124 46.9512 56.0605 46.7851C56.0605 46.6744 55.9046 46.2867 55.6452 45.6222L54.7121 43.0751ZM54.1929 41.968L51.2884 34.825L48.2812 41.968H54.1929Z"
                  fill="white"
                />
                <path
                  d="M70.9969 28.2363L80.5919 41.1377V32.2232C80.5919 30.9496 80.4358 30.119 80.0728 29.676C79.606 29.0669 78.8283 28.7902 77.7392 28.7902V28.2363H84.1701V28.7902C83.3403 28.901 82.7694 29.0668 82.5101 29.2329C82.2508 29.3991 81.9921 29.7315 81.8365 30.1191C81.6809 30.5067 81.577 31.2265 81.577 32.2232V49.554H81.1097L67.9365 32.2232V45.4567C67.9365 46.6748 68.1966 47.4497 68.7152 47.8927C69.2339 48.3356 69.8035 48.502 70.4777 48.502H70.945V49.0556H64.0469V48.502C65.136 48.502 65.863 48.2805 66.2779 47.7821C66.6928 47.3392 66.8996 46.5641 66.8996 45.4567V30.7835L66.4855 30.2299C66.0706 29.6762 65.7065 29.2885 65.3953 29.1224C65.0842 28.9563 64.6174 28.8457 64.0469 28.8457V28.2918H70.9969V28.2363Z"
                  fill="white"
                />
                <path
                  d="M92.9874 39.0336V45.4567C92.9874 46.5087 93.0394 47.1731 93.1431 47.4499C93.2469 47.7268 93.4549 47.9483 93.7661 48.1698C94.0773 48.3913 94.544 48.4466 95.0626 48.4466H95.6843V49.0001H85.5195V48.4466H86.1412C86.7117 48.4466 87.1785 48.3358 87.4897 48.1143C87.749 47.9482 87.957 47.7267 88.0607 47.3945C88.1645 47.173 88.2165 46.5087 88.2165 45.4567V31.7801C88.2165 30.7281 88.1645 30.0637 88.0607 29.7869C87.957 29.51 87.7489 29.2885 87.4378 29.067C87.1266 28.8455 86.6598 28.7902 86.1412 28.7902H85.5195V28.2363H95.6843V28.7902H95.0626C94.4922 28.7902 94.0253 28.901 93.7142 29.1224C93.4549 29.2885 93.2469 29.5101 93.1431 29.8423C93.0394 30.0638 92.9874 30.7281 92.9874 31.7801V37.6493H99.8855V31.7801C99.8855 30.7281 99.8335 30.0637 99.7297 29.7869C99.626 29.51 99.418 29.2885 99.1068 29.067C98.7956 28.8455 98.3288 28.7902 97.8102 28.7902H97.1885V28.2363H107.353V28.7902H106.732C106.161 28.7902 105.694 28.901 105.383 29.1224C105.124 29.2885 104.916 29.5101 104.812 29.8423C104.708 30.0638 104.656 30.7281 104.656 31.7801V45.4567C104.656 46.5087 104.708 47.1731 104.812 47.4499C104.916 47.7268 105.124 47.9483 105.435 48.1698C105.746 48.3913 106.213 48.4466 106.732 48.4466H107.353V49.0001H97.1885V48.4466H97.8102C98.3807 48.4466 98.8475 48.3358 99.1587 48.1143C99.418 47.9482 99.626 47.7267 99.7297 47.3945C99.8335 47.173 99.8855 46.5087 99.8855 45.4567V39.0336H92.9874Z"
                  fill="white"
                />
                <path
                  d="M134.787 27.738V34.9361H134.216C133.802 33.0535 133.024 31.6137 131.883 30.6171C130.742 29.6204 129.445 29.1223 127.993 29.1223C126.8 29.1223 125.66 29.51 124.623 30.2298C123.585 31.0049 122.858 31.9461 122.392 33.1642C121.769 34.7146 121.51 36.431 121.51 38.3136C121.51 40.1962 121.719 41.8572 122.185 43.4075C122.6 44.9579 123.326 46.0653 124.259 46.8959C125.193 47.6711 126.437 48.0588 127.889 48.0588C129.134 48.0588 130.224 47.7821 131.261 47.173C132.298 46.6193 133.335 45.6225 134.476 44.2382V46.0101C133.387 47.2283 132.246 48.1141 131.054 48.6678C129.861 49.2215 128.512 49.4985 126.904 49.4985C124.83 49.4985 122.962 49.0556 121.355 48.1696C119.747 47.2837 118.502 46.0102 117.621 44.3491C116.739 42.688 116.324 40.916 116.324 39.0334C116.324 37.0401 116.791 35.1574 117.777 33.3856C118.762 31.6137 120.059 30.2295 121.718 29.2328C123.378 28.2361 125.141 27.738 126.956 27.738C128.305 27.738 129.757 28.0702 131.313 28.6792C132.195 29.0668 132.764 29.2328 132.972 29.2328C133.283 29.2328 133.543 29.1224 133.75 28.8455C133.958 28.624 134.113 28.2363 134.165 27.6826L134.787 27.738Z"
                  fill="white"
                />
                <path
                  d="M147.752 27.9033C150.864 27.7925 153.353 28.7339 155.324 30.7826C157.242 32.8313 158.228 35.4338 158.228 38.4791C158.228 41.1369 157.501 43.4625 156.049 45.4558C154.13 48.1136 151.434 49.4423 147.96 49.4423C144.433 49.4423 141.736 48.1688 139.817 45.6218C138.313 43.6284 137.535 41.2477 137.535 38.4791C137.535 35.3784 138.52 32.8313 140.49 30.7826C142.461 28.7893 144.899 27.7925 147.752 27.9033ZM147.96 28.8999C146.196 28.8999 144.848 29.8967 143.914 31.8347C143.136 33.4404 142.773 35.7105 142.773 38.7005C142.773 42.2442 143.343 44.8465 144.536 46.563C145.366 47.7257 146.507 48.3349 147.96 48.3349C148.945 48.3349 149.775 48.0579 150.449 47.5596C151.279 46.8952 151.952 45.8434 152.419 44.4038C152.886 42.9642 153.146 41.0815 153.146 38.8114C153.146 36.0429 152.886 33.9939 152.419 32.665C151.952 31.2808 151.331 30.3397 150.605 29.786C149.775 29.1769 148.893 28.8999 147.96 28.8999Z"
                  fill="white"
                />
                <path
                  d="M167.358 29.399V37.9259H167.928C168.81 37.9259 169.485 37.8151 170.055 37.5382C170.574 37.2614 171.041 36.8184 171.456 36.154C171.819 35.4895 172.078 34.6037 172.181 33.4963H172.7V43.9613H172.181C171.974 41.968 171.507 40.6944 170.677 40.0854C169.847 39.4763 168.966 39.1442 167.928 39.1442H167.358V45.5115C167.358 46.5636 167.41 47.2283 167.514 47.5051C167.618 47.782 167.825 48.0035 168.136 48.225C168.447 48.4464 168.914 48.5017 169.432 48.5017H170.055V49.0553H159.891V48.5017H160.512C161.083 48.5017 161.55 48.391 161.861 48.1695C162.12 48.0034 162.276 47.7819 162.432 47.4497C162.536 47.2282 162.586 46.5636 162.586 45.5115V31.8353C162.586 30.7832 162.536 30.1189 162.432 29.842C162.328 29.5652 162.12 29.3437 161.809 29.1222C161.498 28.9007 161.031 28.8454 160.512 28.8454H159.891V28.2915H176.331V34.2716H175.76C175.604 32.8873 175.293 31.8353 174.723 31.1709C174.152 30.5064 173.374 30.008 172.337 29.7312C171.767 29.5651 170.677 29.5098 169.173 29.5098L167.358 29.399Z"
                  fill="white"
                />
                <path
                  d="M185.355 29.399V37.9259H185.924C186.806 37.9259 187.481 37.8151 188.052 37.5382C188.622 37.2614 189.037 36.8184 189.452 36.154C189.867 35.4895 190.074 34.6037 190.177 33.4963H190.697V43.9613H190.177C190.022 41.968 189.503 40.6944 188.673 40.0854C187.843 39.4763 186.962 39.1442 185.924 39.1442H185.355V45.5115C185.355 46.5636 185.407 47.2283 185.51 47.5051C185.614 47.782 185.821 48.0035 186.132 48.225C186.443 48.4464 186.91 48.5017 187.429 48.5017H188.052V49.0553H177.887V48.5017H178.508C179.079 48.5017 179.546 48.391 179.857 48.1695C180.116 48.0034 180.324 47.7819 180.428 47.4497C180.532 47.2282 180.584 46.5636 180.584 45.5115V31.8353C180.584 30.7832 180.532 30.1189 180.428 29.842C180.324 29.5652 180.116 29.3437 179.805 29.1222C179.494 28.9007 179.027 28.8454 178.508 28.8454H177.887V28.2915H194.327V34.2716H193.756C193.6 32.8873 193.289 31.8353 192.719 31.1709C192.148 30.5064 191.37 30.008 190.333 29.7312C189.763 29.5651 188.673 29.5098 187.169 29.5098L185.355 29.399Z"
                  fill="white"
                />
                <path
                  d="M203.245 29.3992V37.8707H203.609C204.853 37.8707 205.735 37.4832 206.306 36.6527C206.876 35.8221 207.239 34.6041 207.394 32.9984H207.912V43.9062H207.394C207.291 42.7434 207.031 41.7466 206.668 40.9714C206.305 40.1962 205.89 39.6981 205.371 39.4212C204.904 39.1444 204.179 39.0336 203.245 39.0336V44.9028C203.245 46.0656 203.297 46.73 203.401 47.0069C203.505 47.2837 203.661 47.4498 203.92 47.6159C204.179 47.782 204.594 47.8376 205.113 47.8376H206.254C208.069 47.8376 209.52 47.3943 210.609 46.5084C211.698 45.6225 212.477 44.2383 212.996 42.4111H213.514L212.633 49.0001H195.777V48.4466H196.399C196.97 48.4466 197.436 48.3358 197.748 48.1143C198.007 47.9482 198.163 47.7267 198.319 47.3945C198.422 47.173 198.474 46.5087 198.474 45.4567V31.7801C198.474 30.8388 198.474 30.2851 198.422 30.0636C198.319 29.7314 198.163 29.4544 197.903 29.2329C197.54 28.9561 197.021 28.7902 196.399 28.7902H195.777V28.2363H212.062V34.3827H211.544C211.284 32.8877 210.87 31.7802 210.351 31.1711C209.832 30.5067 209.157 30.0083 208.224 29.7314C207.705 29.5653 206.669 29.4546 205.217 29.4546H203.245V29.3992Z"
                  fill="white"
                />
                <path
                  d="M222.851 29.3992V37.8707H223.214C224.459 37.8707 225.341 37.4832 225.911 36.6527C226.481 35.8221 226.844 34.6041 227 32.9984H227.518V43.9062H227C226.896 42.7434 226.636 41.7466 226.273 40.9714C225.91 40.1962 225.495 39.6981 224.977 39.4212C224.51 39.1444 223.784 39.0336 222.851 39.0336V44.9028C222.851 46.0656 222.903 46.73 223.006 47.0069C223.11 47.2837 223.265 47.4498 223.524 47.6159C223.784 47.782 224.2 47.8376 224.718 47.8376H225.859C227.674 47.8376 229.126 47.3943 230.215 46.5084C231.304 45.6225 232.082 44.2383 232.6 42.4111H233.119L232.238 49.0001H215.383V48.4466H216.005C216.575 48.4466 217.042 48.3358 217.353 48.1143C217.612 47.9482 217.768 47.7267 217.924 47.3945C218.028 47.173 218.08 46.5087 218.08 45.4567V31.7801C218.08 30.8388 218.08 30.2851 218.028 30.0636C217.924 29.7314 217.768 29.4544 217.509 29.2329C217.146 28.9561 216.627 28.7902 216.005 28.7902H215.383V28.2363H231.667V34.3827H231.149C230.89 32.8877 230.474 31.7802 229.955 31.1711C229.437 30.5067 228.763 30.0083 227.829 29.7314C227.311 29.5653 226.273 29.4546 224.821 29.4546H222.851V29.3992Z"
                  fill="white"
                />
              </svg>
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              color: "#006241",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            <Box
              sx={{
                background: "white",
                p: "7px",
                borderRadius: "23px",
              }}
            >
              ĐĂNG NHẬP
            </Box>
            <Box sx={{ p: "0 10px 0 51px" }}>
              <svg
                width="18"
                height="21"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 21C1.45 21 0.979167 20.8042 0.5875 20.4125C0.195833 20.0208 0 19.55 0 19V7C0 6.45 0.195833 5.97917 0.5875 5.5875C0.979167 5.19583 1.45 5 2 5H4C4 3.61667 4.4875 2.4375 5.4625 1.4625C6.4375 0.4875 7.61667 0 9 0C10.3833 0 11.5625 0.4875 12.5375 1.4625C13.5125 2.4375 14 3.61667 14 5H16C16.55 5 17.0208 5.19583 17.4125 5.5875C17.8042 5.97917 18 6.45 18 7V19C18 19.55 17.8042 20.0208 17.4125 20.4125C17.0208 20.8042 16.55 21 16 21H2ZM2 19H16V7H2V19ZM9 13C10.3833 13 11.5625 12.5125 12.5375 11.5375C13.5125 10.5625 14 9.38333 14 8H12C12 8.83333 11.7083 9.54167 11.125 10.125C10.5417 10.7083 9.83333 11 9 11C8.16667 11 7.45833 10.7083 6.875 10.125C6.29167 9.54167 6 8.83333 6 8H4C4 9.38333 4.4875 10.5625 5.4625 11.5375C6.4375 12.5125 7.61667 13 9 13ZM6 5H12C12 4.16667 11.7083 3.45833 11.125 2.875C10.5417 2.29167 9.83333 2 9 2C8.16667 2 7.45833 2.29167 6.875 2.875C6.29167 3.45833 6 4.16667 6 5Z"
                  fill="white"
                />
              </svg>
            </Box>
            <Box sx={{ color: "white" }}>0</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NavbarOrder;
