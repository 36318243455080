import { Box } from "@mui/material";
import React from "react";

const LogoThanhCoffee = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <svg
        width="101"
        height="23"
        viewBox="0 0 101 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.1991 3.4274V2.88053H47.0173V3.4274H45.4164V7.97144H44.7999V3.4274H43.1991ZM47.9687 7.97144V2.88053H48.5852V5.14757H51.2997V2.88053H51.9162V7.97144H51.2997V5.69445H48.5852V7.97144H47.9687ZM53.1503 7.97144V2.88053H56.2228V3.4274H53.7668V5.14757H56.0637V5.69445H53.7668V7.42456H56.2626V7.97144H53.1503Z"
          fill="white"
        />
        <path
          d="M7.61772 13.0974V15.5161H7.39359C7.25911 14.9707 7.12463 14.5676 6.94533 14.3068C6.78843 14.0696 6.5643 13.8799 6.27293 13.7377C6.11604 13.6665 5.84709 13.6191 5.44365 13.6191H4.81608V20.4958C4.81608 20.9464 4.83849 21.231 4.88332 21.3495C4.92815 21.4681 5.0178 21.5629 5.15228 21.6578C5.28675 21.7526 5.48847 21.7763 5.7126 21.7763H5.98156V22.0135H1.56618V21.7763H1.83513C2.08168 21.7763 2.28339 21.7289 2.41787 21.6341C2.52993 21.5629 2.61959 21.4681 2.66442 21.3258C2.70924 21.2309 2.73165 20.9464 2.73165 20.4958V13.6191H2.1265C1.56618 13.6191 1.14032 13.7377 0.893774 13.9985C0.535164 14.3542 0.311034 14.8522 0.221381 15.5161H-0.00274658V13.0974H7.61772Z"
          fill="white"
        />
        <path
          d="M11.5629 17.7214V20.4721C11.5629 20.9227 11.5853 21.2072 11.6301 21.3258C11.675 21.4444 11.7646 21.5392 11.8991 21.6341C12.0336 21.7289 12.2353 21.7526 12.4594 21.7526H12.7284V21.9898H8.33539V21.7526H8.60435C8.8509 21.7526 9.05261 21.7052 9.18709 21.6103C9.29916 21.5392 9.38881 21.4444 9.43364 21.3021C9.47846 21.2072 9.50087 20.9227 9.50087 20.4721V14.615C9.50087 14.1645 9.47846 13.8799 9.43364 13.7614C9.38881 13.6428 9.29916 13.548 9.16468 13.4531C9.0302 13.3583 8.82848 13.3345 8.60435 13.3345H8.33539V13.0974H12.7284V13.3345H12.4594C12.2129 13.3345 12.0111 13.382 11.8767 13.4768C11.7646 13.5479 11.675 13.6428 11.6301 13.7851C11.5853 13.8799 11.5629 14.1645 11.5629 14.615V17.1286H14.5438V14.615C14.5438 14.1645 14.5214 13.8799 14.4766 13.7614C14.4318 13.6428 14.3421 13.548 14.2076 13.4531C14.0732 13.3583 13.8714 13.3345 13.6473 13.3345H13.3783V13.0974H17.7713V13.3345H17.5024C17.2558 13.3345 17.0541 13.382 16.9196 13.4768C16.8076 13.5479 16.7179 13.6428 16.6731 13.7851C16.6283 13.8799 16.6058 14.1645 16.6058 14.615V20.4721C16.6058 20.9227 16.6283 21.2072 16.6731 21.3258C16.7179 21.4444 16.8076 21.5392 16.942 21.6341C17.0765 21.7289 17.2782 21.7526 17.5024 21.7526H17.7713V21.9898H13.3783V21.7526H13.6473C13.8939 21.7526 14.0956 21.7052 14.23 21.6103C14.3421 21.5392 14.4318 21.4444 14.4766 21.3021C14.5214 21.2072 14.5438 20.9227 14.5438 20.4721V17.7214H11.5629Z"
          fill="white"
        />
        <path
          d="M23.2621 19.4756H20.2811L19.9225 20.353C19.8105 20.6375 19.7432 20.8747 19.7432 21.0644C19.7432 21.3015 19.8329 21.4912 20.0346 21.6097C20.1467 21.6809 20.4156 21.7283 20.8639 21.7757V22.0129H18.0622V21.7757C18.3536 21.7283 18.6226 21.586 18.8019 21.3726C19.0036 21.1592 19.2277 20.7087 19.5191 20.021L22.5225 12.9071H22.6345L25.6603 20.2107C25.9517 20.8984 26.1982 21.3489 26.3775 21.5149C26.512 21.6572 26.7137 21.7283 26.9827 21.752V21.9892H22.9035V21.752H23.0604C23.3966 21.752 23.6207 21.7046 23.7552 21.6097C23.8448 21.5386 23.8897 21.4438 23.8897 21.3015C23.8897 21.2303 23.8672 21.1355 23.8448 21.0644C23.8448 21.0169 23.7776 20.8509 23.6655 20.5664L23.2621 19.4756ZM23.038 18.9776L21.7828 15.9187L20.4829 18.9776H23.038Z"
          fill="white"
        />
        <path
          d="M30.3221 13.0974L34.4685 18.6225V14.8047C34.4685 14.2593 34.4013 13.9037 34.2444 13.714C34.0427 13.4531 33.7065 13.3345 33.2358 13.3345V13.0974H36.015V13.3345C35.6564 13.382 35.4099 13.4531 35.2978 13.5242C35.1857 13.5954 35.0737 13.7377 35.0064 13.9036C34.9392 14.0696 34.8944 14.3779 34.8944 14.8047V22.2269H34.6927L28.9997 14.8047V20.4721C28.9997 20.9938 29.1118 21.3258 29.3359 21.5155C29.56 21.7052 29.8066 21.7763 30.098 21.7763H30.2997V22.0135H27.3187V21.7763C27.7894 21.7763 28.1032 21.6815 28.2825 21.4681C28.4618 21.2784 28.5514 20.9464 28.5514 20.4721V14.1882L28.3721 13.9511C28.1928 13.7139 28.036 13.5479 27.9015 13.4768C27.767 13.4057 27.5653 13.3583 27.3187 13.3583V13.1211H30.3221V13.0974Z"
          fill="white"
        />
        <path
          d="M39.8026 17.7214V20.4721C39.8026 20.9227 39.825 21.2072 39.8698 21.3258C39.9147 21.4444 40.0043 21.5392 40.1388 21.6341C40.2733 21.7289 40.475 21.7526 40.6991 21.7526H40.9681V21.9898H36.5751V21.7526H36.8441C37.0906 21.7526 37.2923 21.7052 37.4268 21.6103C37.5389 21.5392 37.6285 21.4444 37.6733 21.3021C37.7182 21.2072 37.7406 20.9227 37.7406 20.4721V14.615C37.7406 14.1645 37.7182 13.8799 37.6733 13.7614C37.6285 13.6428 37.5389 13.548 37.4044 13.4531C37.2699 13.3583 37.0682 13.3345 36.8441 13.3345H36.5751V13.0974H40.9681V13.3345H40.6991C40.4526 13.3345 40.2509 13.382 40.1164 13.4768C40.0043 13.5479 39.9147 13.6428 39.8698 13.7851C39.825 13.8799 39.8026 14.1645 39.8026 14.615V17.1286H42.7835V14.615C42.7835 14.1645 42.7611 13.8799 42.7163 13.7614C42.6715 13.6428 42.5818 13.548 42.4473 13.4531C42.3129 13.3583 42.1111 13.3345 41.887 13.3345H41.6181V13.0974H46.011V13.3345H45.7421C45.4955 13.3345 45.2938 13.382 45.1593 13.4768C45.0473 13.5479 44.9576 13.6428 44.9128 13.7851C44.868 13.8799 44.8456 14.1645 44.8456 14.615V20.4721C44.8456 20.9227 44.868 21.2072 44.9128 21.3258C44.9576 21.4444 45.0473 21.5392 45.1818 21.6341C45.3162 21.7289 45.518 21.7526 45.7421 21.7526H46.011V21.9898H41.6181V21.7526H41.887C42.1336 21.7526 42.3353 21.7052 42.4698 21.6103C42.5818 21.5392 42.6715 21.4444 42.7163 21.3021C42.7611 21.2072 42.7835 20.9227 42.7835 20.4721V17.7214H39.8026Z"
          fill="white"
        />
        <path
          d="M57.8904 12.9074V15.9901H57.6439C57.4646 15.1839 57.1284 14.5674 56.6353 14.1405C56.1422 13.7137 55.5819 13.5003 54.9543 13.5003C54.4388 13.5003 53.9457 13.6663 53.4975 13.9745C53.0492 14.3065 52.7354 14.7096 52.5337 15.2313C52.2647 15.8953 52.1527 16.6304 52.1527 17.4366C52.1527 18.2429 52.2423 18.9542 52.444 19.6182C52.6458 20.2822 52.9371 20.7564 53.3406 21.1121C53.744 21.4441 54.2819 21.6101 54.9095 21.6101C55.4474 21.6101 55.9181 21.4915 56.3663 21.2307C56.8146 20.9936 57.2629 20.5667 57.756 19.9739V20.7327C57.2853 21.2544 56.7922 21.6338 56.2767 21.8709C55.7612 22.1081 55.1785 22.2266 54.4836 22.2266C53.5871 22.2266 52.7802 22.0369 52.0854 21.6575C51.3906 21.2781 50.8527 20.7327 50.4717 20.0213C50.0907 19.3099 49.9114 18.5511 49.9114 17.7449C49.9114 16.8912 50.1131 16.085 50.5389 15.3262C50.9648 14.5674 51.5251 13.9745 52.2423 13.5477C52.9596 13.1209 53.7216 12.9074 54.506 12.9074C55.0888 12.9074 55.7164 13.0497 56.3888 13.3106C56.7698 13.4766 57.0163 13.5477 57.106 13.5477C57.2405 13.5477 57.3525 13.5003 57.4422 13.3817C57.5318 13.2632 57.5991 13.1209 57.6215 12.8837L57.8904 12.9074Z"
          fill="white"
        />
        <path
          d="M63.4934 12.9785C64.8382 12.9311 65.914 13.3342 66.7657 14.2116C67.595 15.089 68.0209 16.2035 68.0209 17.5077C68.0209 18.6459 67.7071 19.6419 67.0795 20.4955C66.2502 21.6338 65.0847 22.2029 63.5831 22.2029C62.059 22.2029 60.8935 21.6575 60.0642 20.5667C59.4142 19.713 59.078 18.6934 59.078 17.5077C59.078 16.1798 59.5039 15.089 60.3556 14.2116C61.2073 13.3342 62.2383 12.9311 63.4934 12.9785ZM63.5606 13.4054C62.7986 13.4054 62.2158 13.8322 61.8124 14.6621C61.4762 15.3498 61.3193 16.3221 61.3193 17.6026C61.3193 19.1202 61.5659 20.2347 62.0814 20.9698C62.44 21.4678 62.9331 21.7286 63.5606 21.7286C63.9865 21.7286 64.3451 21.61 64.6365 21.3966C64.9951 21.1121 65.2864 20.6615 65.4882 20.045C65.6899 19.4285 65.8019 18.6222 65.8019 17.65C65.8019 16.4643 65.6899 15.587 65.4882 15.0178C65.2864 14.425 65.0175 14.0219 64.7037 13.7848C64.3451 13.5239 63.9865 13.4054 63.5606 13.4054Z"
          fill="white"
        />
        <path
          d="M71.9657 13.6193V17.2711H72.2122C72.5933 17.2711 72.8847 17.2237 73.1312 17.1051C73.3553 16.9865 73.557 16.7968 73.7363 16.5123C73.8932 16.2277 74.0053 15.8483 74.0501 15.374H74.2743V19.8558H74.0501C73.9605 19.0021 73.7588 18.4567 73.4001 18.1959C73.0415 17.935 72.6605 17.7928 72.2122 17.7928H71.9657V20.5197C71.9657 20.9703 71.9881 21.2548 72.0329 21.3734C72.0778 21.492 72.1674 21.5868 72.3019 21.6817C72.4364 21.7765 72.6381 21.8002 72.8622 21.8002H73.1312V22.0374H68.7382V21.8002H69.0072C69.2537 21.8002 69.4554 21.7528 69.5899 21.658C69.702 21.5868 69.7916 21.492 69.8365 21.3497C69.8813 21.2549 69.9037 20.9703 69.9037 20.5197V14.6626C69.9037 14.2121 69.8813 13.9276 69.8365 13.809C69.7916 13.6904 69.702 13.5956 69.5675 13.5007C69.433 13.4059 69.2313 13.3822 69.0072 13.3822H68.7382V13.145H75.8432V15.706H75.5966C75.5294 15.1132 75.3949 14.6627 75.1484 14.3781C74.9018 14.0935 74.5656 13.8801 74.1174 13.7616C73.8708 13.6904 73.4001 13.6667 72.7502 13.6667L71.9657 13.6193Z"
          fill="white"
        />
        <path
          d="M79.7205 13.6193V17.2711H79.9671C80.3481 17.2711 80.6395 17.2237 80.886 17.1051C81.1101 16.9865 81.3119 16.7968 81.4912 16.5123C81.6481 16.2277 81.7601 15.8483 81.8049 15.374H82.0291V19.8558H81.8049C81.7153 19.0021 81.5136 18.4567 81.155 18.1959C80.7964 17.935 80.4153 17.7928 79.9671 17.7928H79.7205V20.5197C79.7205 20.9703 79.743 21.2548 79.7878 21.3734C79.8326 21.492 79.9222 21.5868 80.0567 21.6817C80.1912 21.7765 80.3929 21.8002 80.6171 21.8002H80.886V22.0374H76.493V21.8002H76.762C77.0085 21.8002 77.2103 21.7528 77.3447 21.658C77.4568 21.5868 77.5465 21.492 77.5913 21.3497C77.6361 21.2549 77.6585 20.9703 77.6585 20.5197V14.6626C77.6585 14.2121 77.6361 13.9276 77.5913 13.809C77.5465 13.6904 77.4568 13.5956 77.3223 13.5007C77.1878 13.4059 76.9861 13.3822 76.762 13.3822H76.493V13.145H83.598V15.706H83.3515C83.2842 15.1132 83.1497 14.6627 82.9032 14.3781C82.6567 14.0935 82.3205 13.8801 81.8722 13.7616C81.6257 13.6904 81.155 13.6667 80.505 13.6667L79.7205 13.6193Z"
          fill="white"
        />
        <path
          d="M87.4535 13.6191V17.2472H87.6104C88.1483 17.2472 88.5293 17.0812 88.7759 16.7255C89.0224 16.3698 89.1793 15.8481 89.2466 15.1605H89.4707V19.8319H89.2466C89.2017 19.3339 89.0897 18.9071 88.9328 18.5751C88.7759 18.2431 88.5966 18.0297 88.3724 17.9112C88.1707 17.7926 87.8569 17.7452 87.4535 17.7452V20.2587C87.4535 20.7567 87.4759 21.0413 87.5207 21.1598C87.5656 21.2784 87.6328 21.3495 87.7449 21.4207C87.8569 21.4918 88.0363 21.5155 88.2604 21.5155H88.7535C89.5379 21.5155 90.1655 21.3258 90.6362 20.9464C91.1069 20.567 91.443 19.9742 91.6672 19.1917H91.8913L91.5103 22.0135H84.226V21.7764H84.495C84.7415 21.7764 84.9432 21.7289 85.0777 21.6341C85.1898 21.5629 85.2794 21.4681 85.3243 21.3258C85.3691 21.231 85.3915 20.9464 85.3915 20.4959V14.6388C85.3915 14.2357 85.3915 13.9985 85.3691 13.9037C85.3243 13.7614 85.257 13.6428 85.145 13.548C84.9881 13.4294 84.7639 13.3583 84.495 13.3583H84.226V13.1212H91.2637V15.7533H91.0172C90.9051 15.113 90.7258 14.6388 90.5017 14.3779C90.2776 14.0934 89.9862 13.88 89.5828 13.7614C89.3586 13.6903 88.9104 13.6428 88.2828 13.6428L87.4535 13.6191Z"
          fill="white"
        />
        <path
          d="M95.9482 13.6191V17.2472H96.1051C96.643 17.2472 97.024 17.0812 97.2706 16.7255C97.5171 16.3698 97.674 15.8481 97.7412 15.1605H97.9654V19.8319H97.7412C97.6964 19.3339 97.5844 18.9071 97.4275 18.5751C97.2706 18.2431 97.0913 18.0297 96.8671 17.9112C96.6654 17.7926 96.3516 17.7452 95.9482 17.7452V20.2587C95.9482 20.7567 95.9706 21.0413 96.0154 21.1598C96.0603 21.2784 96.1275 21.3495 96.2396 21.4207C96.3516 21.4918 96.5309 21.5155 96.7551 21.5155H97.2482C98.0326 21.5155 98.6602 21.3258 99.1309 20.9464C99.6015 20.567 99.9377 19.9742 100.162 19.1917H100.386L100.005 22.0135H92.7207V21.7764H92.9897C93.2362 21.7764 93.4379 21.7289 93.5724 21.6341C93.6845 21.5629 93.7741 21.4681 93.8189 21.3258C93.8638 21.231 93.8862 20.9464 93.8862 20.4959V14.6388C93.8862 14.2357 93.8862 13.9985 93.8638 13.9037C93.819 13.7614 93.7517 13.6428 93.6397 13.548C93.4828 13.4294 93.2586 13.3583 92.9897 13.3583H92.7207V13.1212H99.7584V15.7533H99.5119C99.3998 15.113 99.2205 14.6388 98.9964 14.3779C98.7723 14.0934 98.4809 13.88 98.0774 13.7614C97.8533 13.6903 97.405 13.6428 96.7775 13.6428L95.9482 13.6191Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

export default LogoThanhCoffee;
