import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";

const FavoriteIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8118 9.55532C16.3134 11.7222 13.3166 14.5414 10.1185 16.2656C6.92035 14.5414 3.92352 11.7222 2.4251 9.55532C1.44106 8.13404 0.568858 6.50305 0.613587 4.73227C0.658316 2.89159 1.79893 1.19073 3.36443 0.445136C4.92994 -0.300454 6.8309 -0.0907769 8.23986 0.934409C9.04498 1.5169 9.69356 2.30907 10.1409 3.24106C10.5881 2.30907 11.2367 1.4936 12.0418 0.934409C13.4732 -0.0674772 15.3741 -0.300454 16.9173 0.445136C18.4828 1.19073 19.601 2.91489 19.668 4.73227C19.668 6.50305 18.7959 8.13404 17.8118 9.55532Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

export default FavoriteIcon;
