import { Box } from "@mui/material";
import React from "react";

const LeftArrowBlackW9H16 = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <svg
        width="9"
        height="16"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L5.07769 4.90318L1 8.5647"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default LeftArrowBlackW9H16;
