import { Box } from "@mui/material";
import React from "react";

const MapsIcon = () => {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M8.53202 12.848L19.3665 8.5177L14.4042 19.1768L14.5576 13.849L14.5712 13.3778L14.1015 13.3366L8.53202 12.848Z"
            fill="#06542D"
            stroke="#06542D"
          />
          <path
            id="Vector_2"
            d="M13.8347 26.6694C20.9231 26.6694 26.6694 20.9231 26.6694 13.8347C26.6694 6.74626 20.9231 1 13.8347 1C6.74626 1 1 6.74626 1 13.8347C1 20.9231 6.74626 26.6694 13.8347 26.6694Z"
            stroke="#06542D"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    </Box>
  );
};

export default MapsIcon;
