import { Box } from "@mui/material";
import NavBar from "../../component/layout/NavBar";
import Footer from "../../component/layout/Footer";
import { styles } from "../../styles";
import Layout from "../../component/layout";
const Desktop = () => {
  return (
    <Box sx={{ ...styles.fontSize13, fontSize: "20px" }}>
      <NavBar />
      <Layout.SecondaryBg>
        <Box sx={{ p: "80px 20px", textAlign: "center" }}>
          <Box
            sx={{
              fontSize: "32px",
              ...styles.fontColor.xanhNhat006241,
              fontWeight: 500,
            }}
          >
            TheThanh Coffee
          </Box>
          <Box
            sx={{
              p: "15px 0px",
              fontSize: "48px",
              ...styles.fontColor.xanhNhat006241,
              fontWeight: 700,
            }}
          >
            ĐIỀU KHOẢN VÀ ĐIỀU KIỆN
          </Box>
          <Box>ngày thêm: 28/05/2024</Box>
        </Box>
        <Box
          sx={{
            p: "20px",
            ul: { paddingLeft: "20px" },
            overflowWrap: "break-word",
            wordWrap: "break-word",
          }}
        >
          <Box sx={{ borderTop: "solid black 1px", p: "30px 20px" }}>
            <Box>VUI LÒNG ĐỌC KỸ CÁC ĐIỀU KHOẢN SỬ DỤNG NÀY. </Box>
            <br />
            <Box>
              TRUY CẬP HOẶC SỬ DỤNG TRANG WEB NÀY CÓ nghĩa là CHẤP NHẬN CÁC ĐIỀU
              KHOẢN SỬ DỤNG NÀY (“ĐIỀU KHOẢN”), NHƯ VẬY CÓ THỂ ĐƯỢC The Thanh
              Coffee SỬA ĐỔI THEO THỜI GIAN VÀ LÀ THỎA THUẬN RÀNG BUỘC GIỮA
              NGƯỜI DÙNG (“NGƯỜI DÙNG” HOẶC “BẠN”) VÀ CÔNG TY The Thanh Coffee
              (D/B/A CÔNG TY CÀ PHÊ The Thanh Coffee) QUẢN LÝ VIỆC SỬ DỤNG TRANG
              WEB. NẾU NGƯỜI DÙNG KHÔNG ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN NÀY, NGƯỜI
              DÙNG KHÔNG NÊN TRUY CẬP HOẶC SỬ DỤNG TRANG WEB NÀY. CÁC ĐIỀU KHOẢN
              NÀY CHỨA TUYÊN BỐ TỪ CHỐI TRÁCH NHIỆM VÀ CÁC QUY ĐỊNH KHÁC GIỚI
              HẠN TRÁCH NHIỆM PHÁP LÝ CỦA CHÚNG TÔI ĐỐI VỚI NGƯỜI DÙNG.
            </Box>
            <br />
            <Box>
              Các Điều khoản này áp dụng cho việc bạn truy cập và sử dụng tất cả
              hoặc một phần trang web hoặc ứng dụng di động bất kỳ của Tổng Công
              ty The Thanh Coffee hoặc các công ty con và công ty liên kết của
              Tổng Công ty The Thanh Coffee (gọi chung là “The Thanh Coffee”),
              bao gồm www.thanhcoffee.com và bất kỳ trang web, ứng dụng di động
              hoặc dịch vụ trực tuyến nào khác nơi các Điều khoản này được đăng
              (gọi chung là “Trang web”). Các Điều khoản này không thay đổi các
              điều khoản hoặc điều kiện của bất kỳ thỏa thuận nào khác mà bạn có
              thể có với The Thanh Coffee về sản phẩm, dịch vụ hoặc mặt khác
              theo bất kỳ cách nào.
            </Box>
            <br />
            <Box>
              Trong trường hợp có bất kỳ xung đột hoặc mâu thuẫn nào giữa các
              Điều khoản này và bất kỳ điều khoản sử dụng nào khác xuất hiện
              trên Trang web thì các Điều khoản này sẽ được ưu tiên áp dụng. Tuy
              nhiên, nếu bạn điều hướng khỏi Trang web để đến trang web của bên
              thứ ba, bạn có thể phải tuân theo các điều khoản và điều kiện sử
              dụng thay thế, như có thể được chỉ định trên trang web đó, điều
              này sẽ chi phối việc bạn sử dụng trang web đó.
            </Box>
            <br />
            <Box>
              Mặc dù chúng tôi nỗ lực hợp lý để cung cấp thông tin chính xác và
              kịp thời về Tổng Công ty The Thanh Coffee trên Trang web, nhưng
              bạn không nên cho rằng thông tin đó luôn cập nhật hoặc Trang web
              chứa tất cả thông tin liên quan có sẵn về The Thanh Coffee. Đặc
              biệt, nếu bạn đang đưa ra quyết định đầu tư liên quan đến The
              Thanh Coffee, vui lòng tham khảo một số nguồn khác nhau, bao gồm
              hồ sơ của The Thanh Coffee gửi lên cơ quan có thẩm quyền.
            </Box>
            <br />
            <Box>
              Các Điều khoản này bao gồm thỏa thuận Quan toà chi phối mọi tranh
              chấp giữa bạn và chúng tôi. Trong Quan toà, ít có sự phát hiện và
              xét xử phúc thẩm hơn so với tại tòa án. Thỏa thuận Quan toà này và
              các điều khoản khác sẽ:
            </Box>
            <ul>
              <li>
                Loại bỏ quyền được xét xử bởi bồi thẩm đoàn của bạn trong phạm
                vi được pháp luật hiện hành cho phép; Và
              </li>
              <br />
              <li>
                Ảnh hưởng đáng kể đến các quyền của bạn, bao gồm cả việc ngăn
                cản bạn đưa, tham gia hoặc tham gia vào các thủ tục tố tụng tập
                thể hoặc hợp nhất trong Quan toà và kiện tụng.
              </li>
            </ul>
            <Box>
              Bạn đồng ý rằng chúng tôi có thể cung cấp thông báo, tiết lộ và
              sửa đổi các Điều khoản này bằng phương tiện điện tử, bao gồm cả
              việc thay đổi các Điều khoản này bằng cách đăng các bản sửa đổi
              trên Trang web.
            </Box>
            <br />
            <h4>Đủ điều kiện</h4>
            <Box>
              Trang web không nhằm mục đích hướng tới hoặc nhằm mục đích sử dụng
              bởi bất kỳ ai dưới 13 tuổi. NGƯỜI DÙNG PHẢI ÍT NHẤT 13 TUỔI ĐỂ
              TRUY CẬP VÀ SỬ DỤNG TRANG WEB. Nếu Người dùng ở độ tuổi từ 13 đến
              18, người đó chỉ có thể sử dụng Trang web dưới sự giám sát của cha
              mẹ hoặc người giám hộ hợp pháp đồng ý bị ràng buộc bởi các Điều
              khoản này. Người dùng tuyên bố và bảo đảm rằng (a) họ không sống ở
              quốc gia bị chính phủ Việt Nam cấm vận hoặc bị chính phủ Việt Nam
              chỉ định là quốc gia “hỗ trợ khủng bố”; và (b) anh ấy/cô ấy không
              có tên trong bất kỳ danh sách các bên bị cấm hoặc hạn chế nào của
              chính phủ Việt Nam.
            </Box>
            <br />
            <Box>
              Để tham gia vào một số lĩnh vực nhất định trên Trang web của chúng
              tôi, bạn cần phải đăng ký một tài khoản. Bạn đồng ý (a) chỉ tạo
              một tài khoản; (b) cung cấp thông tin chính xác, trung thực, cập
              nhật và đầy đủ khi tạo tài khoản của bạn; (c) duy trì và cập nhật
              kịp thời thông tin tài khoản của bạn; (d) duy trì tính bảo mật cho
              tài khoản của bạn bằng cách không chia sẻ mật khẩu của bạn với
              người khác và hạn chế quyền truy cập vào tài khoản và máy tính của
              bạn; (e) thông báo kịp thời cho The Thanh Coffee nếu bạn phát hiện
              hoặc nghi ngờ bất kỳ vi phạm bảo mật nào liên quan đến Trang web;
              và (f) chịu trách nhiệm về mọi hoạt động diễn ra Quan toà khoản
              của bạn và chấp nhận mọi rủi ro về việc truy cập trái phép.
            </Box>
            <h4>Sự riêng tư</h4>
            <Box>
              Vui lòng đọc kỹ Chính sách quyền riêng tư để hiểu cách The Thanh
              Coffee thu thập, sử dụng và tiết lộ thông tin nhận dạng cá nhân từ
              người dùng của mình. Bằng cách truy cập hoặc sử dụng Trang web,
              bạn đồng ý với mọi hành động chúng tôi thực hiện đối với dữ liệu
              của bạn phù hợp với Chính sách quyền riêng tư của chúng tôi.
            </Box>
            <h4>
              Thẻ The Thanh Coffee; Chương trình Phần thưởng The Thanh Coffee®
            </h4>
            <Box>
              The Thanh Coffee có thể cho phép bạn mua thẻ giá trị được lưu trữ
              của The Thanh Coffee (“Thẻ The Thanh Coffee”) hoặc đăng ký chương
              trình The Thanh Coffee Rewards (“Chương trình Phần thưởng”) thông
              qua Trang web. Vui lòng tham khảo Điều khoản & Điều kiện của Thẻ
              The Thanh Coffee để biết thêm thông tin về các điều khoản, điều
              kiện và chính sách áp dụng cho việc bạn mua và sử dụng Thẻ The
              Thanh Coffee; và Điều khoản sử dụng của The Thanh Coffee Rewards
              để biết thêm thông tin về các điều khoản, điều kiện và chính sách
              áp dụng cho việc đăng ký và sử dụng Chương trình Phần thưởng của
              bạn.
            </Box>
            <h4>Truyền thông qua email</h4>
            <Box>
              Nếu Người dùng đăng ký tài khoản The Thanh Coffee trên Trang web,
              theo mặc định, Người dùng sẽ được chọn tham gia nhận thông tin
              liên lạc qua email quảng cáo từ The Thanh Coffee (“Thông tin liên
              lạc qua email”). Tại thời điểm đăng ký, Người dùng có thể chọn
              không tham gia nhận Thông tin qua Email từ The Thanh Coffee. Sau
              đó, Người dùng có thể từ chối nhận Thông tin qua Email bằng cách
              điều chỉnh cài đặt hồ sơ của Người dùng Quan toà khoản The Thanh
              Coffee của Người dùng qua www.thanhcoffee.com .
            </Box>
            <h4>Bản quyền, Thương hiệu và Giấy phép Người dùng</h4>
            <Box>
              Trừ khi có quy định khác, Trang web và tất cả nội dung cũng như
              tài liệu khác trong đó, bao gồm nhưng không giới hạn logo The
              Thanh Coffee và tất cả các thiết kế, văn bản, đồ họa, hình ảnh,
              thông tin, dữ liệu, phần mềm, tệp âm thanh, các tệp khác cũng như
              việc lựa chọn và sắp xếp chúng ( gọi chung là “Tài liệu Trang
              web”) là tài sản của The Thanh Coffee hoặc người cấp phép hoặc
              người dùng của The Thanh Coffee và được bảo vệ bởi luật bản quyền
              của Việt Nam và quốc tế. Công ty cà phê The Thanh Coffee, The
              Thanh Coffee, logo The Thanh Coffee và các nhãn hiệu, nhãn hiệu
              dịch vụ, đồ họa và logo khác của The Thanh Coffee được sử dụng
              liên quan đến Trang web là tên thương mại, nhãn hiệu hoặc nhãn
              hiệu đã đăng ký của Tổng Công ty The Thanh Coffee (gọi chung là
              “Nhãn hiệu The Thanh Coffee”). Các nhãn hiệu, nhãn hiệu dịch vụ,
              đồ họa và logo khác được sử dụng liên quan đến Trang web là nhãn
              hiệu hoặc nhãn hiệu đã đăng ký của chủ sở hữu tương ứng (gọi chung
              là “Nhãn hiệu của bên thứ ba”). Không được phép sao chép, bắt
              chước hoặc sử dụng toàn bộ hoặc một phần Nhãn hiệu The Thanh
              Coffee và Nhãn hiệu của bên thứ ba mà không có sự cho phép trước
              bằng văn bản của The Thanh Coffee hoặc chủ sở hữu nhãn hiệu hiện
              hành. Trang web và Nội dung được bảo vệ bởi bản quyền, nhãn hiệu,
              bằng sáng chế, bí mật thương mại, điều ước quốc tế, luật tiểu bang
              và liên bang cũng như các quyền sở hữu khác và cũng có thể có các
              thành phần bảo mật chỉ bảo vệ thông tin kỹ thuật số khi được The
              Thanh Coffee hoặc chủ sở hữu Nội dung cho phép . Tất cả các quyền
              không được cấp đều được bảo lưu.
            </Box>
            <br />
            <Box>
              Theo các Điều khoản này, The Thanh Coffee cấp cho Người dùng giấy
              phép cá nhân, không độc quyền, không thể chuyển nhượng, có giới
              hạn và có thể hủy bỏ để sử dụng Trang web chỉ cho mục đích sử dụng
              cá nhân theo các Điều khoản này (“Giấy phép Người dùng”). Bất kỳ
              việc sử dụng Trang web nào theo bất kỳ cách nào khác, bao gồm
              nhưng không giới hạn ở việc bán lại, chuyển nhượng, sửa đổi hoặc
              phân phối Trang web hoặc văn bản, hình ảnh, âm nhạc, mã vạch,
              video, dữ liệu, siêu liên kết, màn hình và nội dung khác được liên
              kết với Trang web ( “Nội dung”) bị cấm. Trừ khi được quy định rõ
              ràng ở đây, không có nội dung nào trong các Điều khoản này sẽ được
              hiểu là trao dưới bất kỳ hình thức nào, dù bằng ngụ ý, sự phản đối
              hay cách khác, bất kỳ quyền sở hữu hoặc quyền sở hữu hoặc quyền sử
              dụng độc quyền đối với bất kỳ quyền sở hữu trí tuệ hoặc quyền nào
              khác và bất kỳ thiện chí nào liên quan đến chúng. Các Điều khoản
              và Giấy phép Người dùng này cũng chi phối mọi cập nhật hoặc bổ
              sung hoặc thay thế cho Trang web, trừ khi các điều khoản riêng
              biệt đi kèm với các cập nhật, bổ sung hoặc thay thế đó, trong
              trường hợp đó các điều khoản riêng sẽ được áp dụng.
            </Box>
            <h4>Chấp thuận sử dụng</h4>
            <Box>
              Việc Người dùng sử dụng Trang web, bất kỳ Nội dung nào và bất kỳ
              thông tin nào do Người dùng cung cấp, bao gồm tên người dùng và
              mật khẩu, địa chỉ, địa chỉ email, số điện thoại, thông tin tài
              chính (chẳng hạn như số thẻ tín dụng), thông tin liên quan đến Thẻ
              The Thanh Coffee hoặc tên chủ lao động ( “Thông tin người dùng”)
              được truyền liên quan đến Trang web bị giới hạn ở chức năng dự
              kiến ​​của Trang web. Trong mọi trường hợp, Trang web không được
              sử dụng theo cách (a) quấy rối, lạm dụng, theo dõi, đe dọa, phỉ
              báng hoặc xâm phạm hoặc vi phạm quyền của bất kỳ bên nào khác (bao
              gồm nhưng không giới hạn ở quyền công khai hoặc các quyền sở hữu
              khác) ; (b) trái pháp luật, gian lận hoặc lừa đảo; (c) cung cấp
              thông tin cá nhân nhạy cảm trừ khi được The Thanh Coffee yêu cầu
              cụ thể, (d) bao gồm thư rác hoặc bất kỳ quảng cáo không được yêu
              cầu nào; (e) sử dụng công nghệ hoặc phương tiện khác để truy cập
              The Thanh Coffee hoặc Nội dung không được The Thanh Coffee cho
              phép; (f) sử dụng hoặc khởi chạy bất kỳ hệ thống tự động nào, bao
              gồm nhưng không giới hạn ở “robot”, “nhện” hoặc “trình đọc ngoại
              tuyến” để truy cập The Thanh Coffee hoặc Nội dung; (g) cố gắng đưa
              vi-rút hoặc bất kỳ mã, tệp hoặc chương trình máy tính nào khác làm
              gián đoạn, phá hủy hoặc hạn chế chức năng của bất kỳ phần mềm,
              phần cứng hoặc thiết bị viễn thông máy tính nào; (h) cố gắng truy
              cập trái phép vào mạng máy tính hoặc tài khoản người dùng của The
              Thanh Coffee; (i) khuyến khích hành vi cấu thành tội phạm hoặc làm
              phát sinh trách nhiệm dân sự; (j) vi phạm các Điều khoản này; (k)
              cố gắng làm hỏng, vô hiệu hóa, làm quá tải hoặc làm hỏng máy chủ
              hoặc mạng của The Thanh Coffee; (l) mạo danh bất kỳ cá nhân hoặc
              tổ chức nào hoặc xuyên tạc danh tính hoặc mối quan hệ của bạn với
              cá nhân hoặc tổ chức khác; hoặc (m) không tuân thủ các điều khoản
              hiện hành của bên thứ ba (gọi chung là “Việc sử dụng được chấp
              nhận”). The Thanh Coffee có quyền, theo quyết định riêng của mình,
              chấm dứt bất kỳ Giấy phép Người dùng nào, chấm dứt sự tham gia của
              bất kỳ Người dùng nào vào Trang web, xóa Nội dung hoặc khẳng định
              hành động pháp lý đối với Nội dung hoặc việc sử dụng Trang web mà
              The Thanh Coffee có lý do hợp lý để tin rằng có hoặc có thể có
              trong đó. vi phạm các Điều khoản này hoặc các chính sách của The
              Thanh Coffee bao gồm Điều khoản và Điều kiện của Thẻ The Thanh
              Coffee. Việc The Thanh Coffee không thực hiện hoặc trì hoãn thực
              hiện những hành động như vậy không cấu thành sự từ bỏ quyền thực
              thi các Điều khoản này.
            </Box>
            <h4>Nội dung người dùng</h4>
            <Box>
              The Thanh Coffee không kiểm soát, chịu trách nhiệm hoặc thừa nhận
              trách nhiệm pháp lý đối với bất kỳ Nội dung người dùng nào được
              đăng, lưu trữ hoặc tải lên bởi bạn hoặc bất kỳ bên thứ ba nào hoặc
              đối với bất kỳ tổn thất hoặc thiệt hại nào liên quan đến nó, The
              Thanh Coffee cũng không chịu trách nhiệm pháp lý đối với bất kỳ
              hành vi nào của người dùng hoặc bất kỳ sai lầm, phỉ báng, vu khống
              nào. , bôi nhọ, thiếu sót, giả dối, tục tĩu, nội dung khiêu dâm
              hoặc tục tĩu mà bạn có thể gặp phải. Các khu vực tương tác thường
              được thiết kế dưới dạng khu vực cộng đồng mở và công cộng để kết
              nối và chia sẻ với người khác. Khi tham gia vào các lĩnh vực này,
              bạn hiểu rằng một số thông tin và nội dung nhất định mà bạn chọn
              đăng có thể được hiển thị công khai. Bạn hoàn toàn chịu trách
              nhiệm về việc sử dụng Trang web của mình và đồng ý tự chịu rủi ro
              khi sử dụng các khu vực tương tác.
            </Box>
            <br />
            <Box>
              Nếu bạn biết Nội dung người dùng mà bạn cho rằng vi phạm các Điều
              khoản này (ngoại trừ vi phạm bản quyền được đề cập trong phần
              Thông báo Đạo luật bản quyền thiên niên kỷ kỹ thuật số), bạn có
              thể báo cáo nội dung đó bằng cách nhấp vào liên kết "Báo cáo lạm
              dụng" hoặc "Gắn cờ" nằm ngay bên dưới mỗi phần Nội dung người
              dùng. Tuy nhiên, việc thực thi các Điều khoản này hoàn toàn theo
              quyết định riêng của chúng tôi và việc không thực thi trong một số
              trường hợp không cấu thành sự từ bỏ quyền thực thi Điều khoản của
              chúng tôi trong các trường hợp khác. Ngoài ra, các Điều khoản này
              không tạo ra bất kỳ quyền hành động riêng tư nào từ phía bất kỳ
              bên thứ ba nào hoặc bất kỳ kỳ vọng hay hứa hẹn hợp lý nào rằng
              Trang web sẽ không chứa bất kỳ nội dung nào bị cấm bởi các Điều
              khoản này. Mặc dù The Thanh Coffee không có nghĩa vụ sàng lọc,
              chỉnh sửa hoặc giám sát bất kỳ Nội dung người dùng nào được đăng
              trên Trang web, nhưng The Thanh Coffee có quyền và có toàn quyền
              quyết định xóa, sàng lọc hoặc chỉnh sửa bất kỳ Nội dung người dùng
              nào trên Trang web bất kỳ lúc nào và vì bất kỳ lý do gì. mà không
              cần thông báo. Bạn hoàn toàn chịu trách nhiệm tạo bản sao dự phòng
              và thay thế bất kỳ Nội dung người dùng nào bạn đăng hoặc lưu trữ
              trên Trang web bằng chi phí và chi phí duy nhất của bạn.
            </Box>
            <br />
            <Box>
              Nếu bạn đang xem Trang web trên máy tính công cộng hoặc đang sử
              dụng máy tính mà nhiều người có thể truy cập, hãy đảm bảo làm theo
              tất cả các hướng dẫn có liên quan để đảm bảo bạn đã ngắt kết nối
              và đăng xuất khỏi Trang web cũng như hệ thống máy tính mà bạn đang
              sử dụng. ngăn chặn Nội dung người dùng trái phép.
            </Box>
            <br />
            <Box>
              Bạn tuyên bố và bảo đảm rằng Nội dung người dùng của bạn không
              phải tuân theo bất kỳ nghĩa vụ bảo mật nào và bạn sở hữu và kiểm
              soát tất cả các quyền đối với Nội dung người dùng, có quyền hợp
              pháp để phân phối và sản xuất Nội dung người dùng đó hoặc có quyền
              cấp các quyền đối với The Thanh Coffee mà bạn cấp ở đây. The Thanh
              Coffee tuyên bố không có quyền sở hữu hoặc quyền kiểm soát đối với
              bất kỳ Nội dung người dùng nào, trừ khi được quy định khác ở đây,
              trên Trang web hoặc trong một thỏa thuận riêng. Tuy nhiên, bằng
              cách gửi hoặc đăng Nội dung người dùng trên Trang web, bạn cấp cho
              The Thanh Coffee và những người được chỉ định của The Thanh Coffee
              giấy phép toàn cầu, vĩnh viễn, không thể thu hồi, không độc quyền,
              được thanh toán đầy đủ và miễn phí bản quyền để sử dụng, bán, sao
              chép, chuẩn bị các tác phẩm phái sinh, kết hợp với các tác phẩm
              khác, thay đổi, dịch, phân phối bản sao, hiển thị, thực hiện, xuất
              bản, cấp phép hoặc cấp phép phụ cho Nội dung người dùng cũng như
              tên và chân dung của bạn được cung cấp liên quan đến việc sử dụng
              Nội dung người dùng đó. Bằng cách đăng Nội dung Người dùng, theo
              đây bạn giải phóng The Thanh Coffee cũng như các đại lý và nhân
              viên của The Thanh Coffee khỏi mọi khiếu nại rằng việc sử dụng đó,
              như được ủy quyền ở trên, vi phạm bất kỳ quyền nào của bạn và bạn
              hiểu rằng bạn sẽ không được hưởng bất kỳ khoản bồi thường nào cho
              bất kỳ việc sử dụng Nội dung Người dùng nào của bạn.
            </Box>
            <h4>Nộp ý tưởng</h4>
            <Box>
              Tách biệt khỏi Nội dung người dùng mà bạn cung cấp, bạn có thể gửi
              câu hỏi, nhận xét, phản hồi, đề xuất, ý tưởng, cải tiến, kế hoạch,
              ghi chú, bản vẽ, tài liệu gốc hoặc sáng tạo hay thông tin khác về
              The Thanh Coffee, Trang web và sản phẩm của chúng tôi (gọi chung
              là " Ideas") thông qua cổng myThe Thanh Coffeeidea.com hoặc bằng
              cách khác. Ý tưởng bạn gửi là tự nguyện, không bí mật, miễn phí và
              không cam kết. Vui lòng không gửi cho chúng tôi Ý tưởng nếu bạn
              muốn được trả tiền hoặc muốn tiếp tục sở hữu hoặc yêu cầu quyền
              đối với chúng; Ý tưởng của bạn có thể tuyệt vời nhưng chúng tôi có
              thể đã có ý tưởng tương tự hoặc tương tự và chúng tôi không muốn
              tranh chấp. Bạn cũng phải thông báo cho chúng tôi nếu bạn có bằng
              sáng chế đang chờ xử lý hoặc đã đăng ký liên quan đến Ý tưởng.
            </Box>
            <br />
            <Box>
              Bạn tuyên bố và bảo đảm rằng Ý tưởng của bạn không phải tuân theo
              bất kỳ nghĩa vụ bảo mật nào hoặc các ràng buộc về sở hữu trí tuệ
              của bên thứ ba và rằng bạn sở hữu và kiểm soát tất cả các quyền
              đối với Ý tưởng cũng như có quyền cấp các quyền cho The Thanh
              Coffee mà bạn cấp ở đây.
            </Box>
            <br />
            <Box>
              Bằng cách gửi Ý tưởng của mình, bạn cấp cho The Thanh Coffee và
              những người được chỉ định của The Thanh Coffee giấy phép toàn cầu,
              vĩnh viễn, không thể thu hồi, không độc quyền, được thanh toán đầy
              đủ và miễn phí bản quyền để sử dụng, bán, sao chép, chuẩn bị các
              tác phẩm phái sinh, kết hợp với các tác phẩm khác, thay đổi, dịch,
              phân phối các bản sao, hiển thị, thực hiện, xuất bản, cấp phép
              hoặc cấp phép phụ cho Ý tưởng và sẽ có quyền sử dụng và phổ biến Ý
              tưởng không hạn chế cho bất kỳ mục đích nào, thương mại hay mục
              đích khác mà không cần thừa nhận hoặc bồi thường cho bạn. Bằng
              cách gửi Ý tưởng của mình, theo đây bạn sẽ giải phóng The Thanh
              Coffee cũng như các đại lý và nhân viên của The Thanh Coffee khỏi
              mọi khiếu nại rằng việc sử dụng đó vi phạm bất kỳ quyền nào của
              bạn.
            </Box>
            <br />
            <Box>
              The Thanh Coffee sẽ sở hữu độc quyền, bao gồm tất cả các quyền sở
              hữu trí tuệ, đối với bất kỳ tác phẩm nào mà The Thanh Coffee tạo
              ra hoặc đã tạo ra từ Ý tưởng hoặc ý tưởng tương tự của riêng mình.
            </Box>
            <h4>Liên kết đến các trang web</h4>
            <Box>
              Bạn được cấp quyền có giới hạn, không độc quyền để tạo các siêu
              liên kết văn bản tới Trang web vì mục đích phi thương mại, miễn là
              các liên kết đó không mô tả The Thanh Coffee theo cách sai trái,
              gây hiểu nhầm, xúc phạm hoặc nói xấu và với điều kiện thêm là
              trang web liên kết đó không chứa bất kỳ nội dung nào. tài liệu tục
              tĩu, khiêu dâm, khiêu dâm hoặc bất hợp pháp hoặc bất kỳ tài liệu
              nào mang tính xúc phạm, quấy rối hoặc phản cảm. Quyền hạn chế này
              có thể bị thu hồi bất cứ lúc nào. Ngoài ra, bạn không được sử dụng
              logo hoặc đồ họa độc quyền khác của The Thanh Coffee để liên kết
              đến Trang web của chúng tôi mà không có sự cho phép rõ ràng bằng
              văn bản của chúng tôi. Hơn nữa, bạn không được sử dụng, đóng khung
              hoặc tận dụng các kỹ thuật đóng khung để đính kèm bất kỳ nhãn
              hiệu, biểu tượng hoặc thông tin độc quyền nào khác của The Thanh
              Coffee, bao gồm các hình ảnh được tìm thấy trên Trang web, nội
              dung của bất kỳ văn bản nào hoặc bố cục/thiết kế của bất kỳ trang
              hoặc biểu mẫu nào có trên Trang web. trên Trang web mà không có sự
              đồng ý rõ ràng bằng văn bản của chúng tôi. Ngoại trừ trường hợp đã
              lưu ý ở trên, bạn không được chuyển giao bất kỳ quyền hoặc giấy
              phép nào dưới hình thức ngụ ý, phản đối hoặc bằng cách khác trong
              hoặc theo bất kỳ bằng sáng chế, nhãn hiệu, bản quyền hoặc quyền sở
              hữu nào của The Thanh Coffee hoặc bất kỳ bên thứ ba nào. The Thanh
              Coffee không đưa ra tuyên bố hay tuyên bố nào về và không chịu
              trách nhiệm về chất lượng, nội dung, tính chất hoặc độ tin cậy của
              các trang web liên kết đến Trang web. Các trang web như vậy không
              thuộc quyền kiểm soát của The Thanh Coffee và The Thanh Coffee
              không chịu trách nhiệm về nội dung của bất kỳ trang web được liên
              kết nào hoặc bất kỳ liên kết nào có trong trang web được liên kết
              hoặc bất kỳ đánh giá, thay đổi hoặc cập nhật nào đối với các trang
              web đó.
            </Box>
            <h4>Sự bồi thường</h4>
            <Box>
              Người dùng đồng ý bảo vệ, bồi thường và giữ cho The Thanh Coffee,
              công ty mẹ, công ty con và các công ty liên kết khác, nhà thầu độc
              lập, nhà cung cấp dịch vụ và nhà tư vấn cũng như nhân viên, nhà
              thầu, đại lý, cán bộ và giám đốc tương ứng của họ (“Người được bồi
              thường của The Thanh Coffee”) khỏi bị tổn hại. bất kỳ và tất cả
              các khiếu nại, vụ kiện, thiệt hại, chi phí, vụ kiện, tiền phạt,
              hình phạt, trách nhiệm pháp lý và chi phí (bao gồm cả phí luật sư)
              (“Khiếu nại”) phát sinh từ hoặc liên quan đến việc Người dùng sử
              dụng hoặc sử dụng sai mục đích Trang web, vi phạm những điều này
              Điều khoản, vi phạm bất kỳ quyền nào của bên thứ ba, bất kỳ Nội
              dung hoặc Ý tưởng của Người dùng nào bạn cung cấp hoặc hành vi của
              bạn liên quan đến Trang web. Bất kể những điều đã nói ở trên, điều
              khoản bồi thường này sẽ không áp dụng cho bất kỳ Khiếu nại nào do
              sơ suất duy nhất của Người được bồi thường The Thanh Coffee gây
              ra. The Thanh Coffee có quyền đảm nhận việc bảo vệ và kiểm soát
              độc quyền mọi vấn đề mà Người dùng phải bồi thường, trong trường
              hợp đó Người dùng sẽ hợp tác để đưa ra bất kỳ biện pháp bảo vệ nào
              có sẵn.
            </Box>
            <h4>Bảo đảm; Tuyên bố miễn trừ trách nhiệm</h4>
            <Box>
              The Thanh Coffee ĐANG CUNG CẤP CÁC TRANG WEB CHO NGƯỜI DÙNG
              “NGUYÊN TRẠNG” VÀ NGƯỜI DÙNG ĐANG TỰ CHỊU RỦI RO khi sử dụng các
              trang web đó. TRONG PHẠM VI TỐI ĐA CHO PHÉP THEO LUẬT HIỆN HÀNH,
              The Thanh Coffee TỪ CHỐI TẤT CẢ CÁC BẢO ĐẢM, DÙ RÕ RÀNG HAY NGỤ Ý,
              BAO GỒM BẤT KỲ BẢO ĐẢM NÀO RẰNG TRANG WEB CÓ THỂ BÁN ĐƯỢC, ĐÁNG
              TIN CẬY, SẴN CÓ, CHÍNH XÁC, PHÙ HỢP VỚI MỘT MỤC ĐÍCH CỤ THỂ HOẶC
              NHU CẦU, KHÔNG VI PHẠM, KHÔNG KHAI THÁC HOẶC VI-RÚT, CÓ THỂ HOẠT
              ĐỘNG TRÊN CƠ SỞ KHÔNG BỊ GIÁN ĐOẠN, RẰNG VIỆC SỬ DỤNG TRANG WEB
              CỦA NGƯỜI DÙNG PHÙ HỢP VỚI LUẬT ÁP DỤNG CHO NGƯỜI DÙNG HOẶC THÔNG
              TIN NGƯỜI DÙNG ĐƯỢC TRUYỀN LIÊN QUAN ĐẾN TRANG WEB SẼ THÀNH CÔNG,
              CHÍNH XÁC HOẶC ĐƯỢC TRUYỀN HOẶC NHẬN MỘT CÁCH AN TOÀN . CÁC TÀI
              LIỆU VÀ THÔNG TIN TRÊN TRANG WEB CÓ THỂ BAO GỒM SAI SÓT KỸ THUẬT
              HOẶC LỖI ĐÁNH THÉP. BẤT CỨ ĐIỀU NÀO TRÊN, KHÔNG CÓ TUYÊN BỐ TỪ
              CHỐI TRÁCH NHIỆM TRONG ĐOẠN NÀY SẼ ÁP DỤNG CHO CÁC BẢO ĐẢM LIÊN
              QUAN ĐẾN THƯƠNG TÍCH CÁ NH N.
            </Box>
            <h4>Không chịu trách nhiệm</h4>
            <Box>
              THEO LUẬT HIỆN HÀNH, BAO GỒM TRÁCH NHIỆM ĐỐI VỚI THƯƠNG TÍCH CÁ
              NHÂN HOẶC CÁC QUYỀN THEO LUẬT PHÁP KHÔNG KHAI THÁC THEO LUẬT VIỆT
              NAM , TRONG MỌI TRƯỜNG HỢP SẼ KHÔNG ĐƯỢC The Thanh Coffee HOẶC
              VIÊN CHỨC, GIÁM ĐỐC, NH N VIÊN, CỔ ĐÔNG HOẶC ĐẠI LÝ CỦA MÌNH (A)
              TRÁCH NHIỆM TRÁCH NHIỆM ĐỐI VỚI NGƯỜI DÙNG VỚI TÔN TRỌNG SỬ DỤNG
              CÁC TRANG WEB, NỘI DUNG HOẶC TÀI LIỆU CÓ TRONG HOẶC ĐƯỢC TRUY CẬP
              QUA TRANG WEB (BAO GỒM KHÔNG GIỚI HẠN BẤT KỲ THIỆT HẠI G Y RA DO
              HOẶC DO NGƯỜI DÙNG TIN CẬY VÀO BẤT KỲ THÔNG TIN NÀO THU ĐƯỢC TỪ
              The Thanh Coffee), HOẶC BẤT KỲ THIỆT HẠI NÀO DO SAI LẦM, SAI SÓT ,
              GIÁN ĐOẠN, XÓA TẬP TIN HOẶC EMAIL, LỖI, KHUYẾT TẬT, VI-RÚT, CHẬM
              HOẠT ĐỘNG HOẶC TRUYỀN HOẶC BẤT KỲ LỖI HIỆU SUẤT NÀO, DÙ CÓ HAY
              KHÔNG DO DO THIÊN CHÚA, LỖI GIAO TIẾP, TRỘM CẮM, HỦY HOẶC TRUY CẬP
              TRÁI PHÉP VÀO HỒ SƠ, CHƯƠNG TRÌNH CỦA The Thanh Coffee HOẶC DỊCH
              VỤ; VÀ (B) CHỊU TRÁCH NHIỆM TRÁCH NHIỆM VỚI NGƯỜI DÙNG VỀ BẤT KỲ
              THIỆT HẠI GIÁN TIẾP, ĐẶC BIỆT, NGẪU NHIÊN, DO HẬU QUẢ, trừng phạt
              HOẶC MẪU MẪU, BAO GỒM KHÔNG GIỚI HẠN, THIỆT HẠI VỀ MẤT TÍN HIỆU,
              MẤT LỢI NHUẬN, MẤT MÁT, TRỘM CẮM HOẶC THAM NHŨ THÔNG TIN NGƯỜI
              DÙNG HOẶC KHÔNG CÓ KHẢ NĂNG SỬ DỤNG CÁC TRANG WEB HOẶC BẤT KỲ TÍNH
              NĂNG NÀO CỦA HỌ. BIỆN PHÁP DUY NHẤT CỦA NGƯỜI DÙNG LÀ NGỪNG SỬ
              DỤNG TRANG WEB.
            </Box>
            <br />
            <Box>
              NẾU BẠN CƯ TRÚ TẠI KHU VỰC QUYỀN KHÁC NGOÀI VIỆT NAM, KHU VỰC
              QUYỀN CỦA BẠN CÓ THỂ KHÔNG CHO PHÉP GIỚI HẠN TRÁCH NHIỆM TRONG HỢP
              ĐỒNG VỚI NGƯỜI TIÊU DÙNG, VÌ VẬY MỘT SỐ HOẶC TẤT CẢ CÁC GIỚI HẠN
              TRÁCH NHIỆM NÀY CÓ THỂ KHÔNG ÁP DỤNG CHO BẠN.
            </Box>
            <h4>
              Nội dung, Trang web, Sản phẩm và Dịch vụ của Bên thứ ba (bao gồm
              cả Quảng cáo và Khuyến mãi)
            </h4>
            <Box>
              The Thanh Coffee có thể cung cấp nội dung của bên thứ ba trên
              Trang web (bao gồm nội dung được nhúng) hoặc liên kết đến các
              trang web, nội dung, ứng dụng, sản phẩm và dịch vụ của bên thứ ba,
              bao gồm cả quảng cáo và khuyến mại (gọi chung là “Nội dung của bên
              thứ ba”) như một dịch vụ cho những người quan tâm. trong thông tin
              này. Chúng tôi không kiểm soát, xác nhận hoặc chấp nhận bất kỳ Nội
              dung của Bên thứ ba nào, bao gồm cả việc đưa vào bất kỳ liên kết
              nào không ngụ ý sự liên kết, chứng thực hoặc chấp nhận của The
              Thanh Coffee đối với bất kỳ trang web nào hoặc bất kỳ thông tin
              nào có trong đó và không thể đảm bảo về tính chính xác hoặc sự
              trọn vẹn. Bạn thừa nhận và đồng ý rằng The Thanh Coffee không chịu
              trách nhiệm hoặc nghĩa vụ pháp lý dưới bất kỳ hình thức nào đối
              với bất kỳ Nội dung của Bên thứ ba nào và không chịu trách nhiệm
              cập nhật hoặc xem xét Nội dung của Bên thứ ba đó. Bạn đồng ý sử
              dụng Nội dung của bên thứ ba có trong đó và bạn phải tự chịu rủi
              ro. Khi bạn truy cập các trang web khác thông qua Nội dung của bên
              thứ ba hoặc tham gia vào các chương trình khuyến mãi hoặc giao
              dịch kinh doanh với bên thứ ba, bạn nên hiểu rằng các điều khoản
              và chính sách của chúng tôi không còn chi phối nữa và các điều
              khoản và chính sách của các trang web bên thứ ba đó giờ đây sẽ
              được áp dụng. Bạn nên xem lại các điều khoản và chính sách hiện
              hành, bao gồm các biện pháp thu thập dữ liệu và quyền riêng tư của
              bất kỳ trang web nào mà bạn điều hướng từ Trang web của chúng tôi.
              Bạn phải tuân thủ mọi điều khoản hiện hành của bên thứ ba khi sử
              dụng Trang web.
            </Box>
            <h4>Sửa đổi trang web</h4>
            <Box>
              The Thanh Coffee có quyền sửa đổi hoặc ngừng tạm thời hoặc vĩnh
              viễn Trang web hoặc bất kỳ tính năng hay phần nào trong đó mà
              không cần thông báo trước. Bạn đồng ý rằng The Thanh Coffee sẽ
              không chịu trách nhiệm pháp lý về bất kỳ sửa đổi, đình chỉ hoặc
              ngừng cung cấp Trang web hay bất kỳ phần nào trong đó.
            </Box>
            <h4>Tiết lộ Tài liệu Tài chính</h4>
            <Box>
              Tuyên bố hướng tới tương lai: Trang web và bất kỳ tài liệu nào do
              The Thanh Coffee phát hành và có sẵn thông qua Trang web, có thể
              chứa các tuyên bố mang tính hướng tới tương lai theo nghĩa của Đạo
              luật cải cách kiện tụng chứng khoán tư nhân Việt Nam năm 1995. Các
              tuyên bố hướng tới tương lai có thể được xác định bởi thực tế là
              chúng không liên quan chặt chẽ đến các sự kiện lịch sử hoặc hiện
              tại. Chúng thường bao gồm những từ như “tin tưởng”, “mong đợi”,
              “dự đoán”, “ước tính”, “dự định”, “kế hoạch”, “tìm kiếm” hoặc
              những từ có ý nghĩa tương tự, hoặc động từ tương lai hoặc động từ
              có điều kiện, chẳng hạn như “sẽ, “nên”, “có thể” hoặc “có thể”.
              Các tuyên bố hướng tới tương lai bao gồm các tuyên bố về hoạt động
              trong tương lai, chi phí, chi tiêu vốn, dòng tiền, phát triển sản
              phẩm, hiệu quả hoạt động, ước tính hoặc xu hướng về doanh thu và
              thu nhập cũng như kế hoạch mở rộng, sáng kiến ​​và dự đoán. Những
              tuyên bố hướng tới tương lai này dựa trên kỳ vọng của chúng tôi kể
              từ ngày những tuyên bố hướng tới tương lai đó được đưa ra và không
              phải là dự đoán hay đảm bảo cho các sự kiện hoặc hoàn cảnh trong
              tương lai. Kết quả và xu hướng thực tế trong tương lai có thể khác
              biệt đáng kể tùy thuộc vào nhiều yếu tố, bao gồm cả rủi ro được
              nêu chi tiết trong hồ sơ của công ty gửi lên Ủy ban Chứng khoán và
              Giao dịch, bao gồm phần “Các yếu tố rủi ro” trong Báo cáo thường
              niên của The Thanh Coffee trên Mẫu 10-K cho báo cáo tài chính gần
              đây nhất. năm đã kết thúc. Công ty không có nghĩa vụ phải cập nhật
              bất kỳ tuyên bố hướng tới tương lai nào.
            </Box>
            <br />
            <Box>
              Thông cáo báo chí: Thông tin trong thông cáo báo chí do The Thanh
              Coffee đưa ra không được coi là chính xác hoặc cập nhật ngoại trừ
              kể từ ngày thông cáo báo chí được đăng. The Thanh Coffee đặc biệt
              từ chối mọi nghĩa vụ cập nhật thông tin trong thông cáo báo chí.
              Trong phạm vi bất kỳ thông tin nào trong đó mang tính hướng tới
              tương lai, nó được thiết kế để phù hợp với nơi chứa an toàn cho
              các tuyên bố hướng tới tương lai và có thể chịu rủi ro vật chất.
            </Box>
            <br />
            <Box>
              Thông tin tài chính của bên thứ ba: Là một dịch vụ, The Thanh
              Coffee có thể cung cấp liên kết đến các trang web hoặc dịch vụ của
              bên thứ ba có chứa thông tin tài chính hoặc đầu tư về The Thanh
              Coffee. The Thanh Coffee không thường xuyên giám sát cũng như
              không kiểm soát nội dung các tuyên bố hoặc trang web của bên thứ
              ba. Theo đó, The Thanh Coffee không xác nhận hoặc chấp nhận cũng
              như không đưa ra bất kỳ tuyên bố hay bảo đảm nào về tính chính xác
              hoặc đầy đủ của các trang web đó hoặc bất kỳ thông tin nào có
              trong đó, bao gồm nhưng không giới hạn ở các báo cáo của nhà phân
              tích và báo giá cổ phiếu. Người dùng truy cập các trang web này và
              tự chịu rủi ro khi sử dụng thông tin có trong đó.
            </Box>
            <br />
            <Box>
              Giải quyết tranh chấp (bao gồm Thỏa thuận Quan toà; Miễn trừ vụ
              kiện tập thể; Miễn trừ xét xử của bồi thẩm đoàn)
            </Box>
            <br />
            <Box>
              Vui lòng đọc kỹ phần này. Nó ảnh hưởng đến quyền lợi hợp pháp của
              bạn. Nó quy định việc giải quyết hầu hết các tranh chấp thông qua
              Quan toà cá nhân thay vì xét xử tại tòa án và kiện tụng tập thể.
              Quan toà không chính thức hơn một vụ kiện tại tòa án, sử dụng Quan
              toà trung lập thay vì thẩm phán hoặc bồi thẩm đoàn và việc phát
              hiện bị hạn chế hơn. Quan toà là quyết định cuối cùng và mang tính
              ràng buộc và chỉ chịu sự xem xét rất hạn chế của tòa án. Phần này
              cũng bao gồm quyền từ bỏ xét xử của bồi thẩm đoàn và từ bỏ bất kỳ
              và tất cả các quyền tiến hành trong một vụ kiện tập thể, tập thể,
              hợp nhất, luật sư tư nhân hoặc đại diện trong Quan toà hoặc kiện
              tụng trong phạm vi tối đa được luật hiện hành cho phép.
            </Box>
            <h4>Thỏa thuận Quan toà</h4>
            <ul>
              <li>
                <strong>
                  <i>Quan toà ràng buộc.</i>
                </strong>
                Quy định này dự kiến ​​sẽ được giải thích theo nghĩa rộng. Bất
                kỳ tranh chấp hoặc khiếu nại nào phát sinh từ hoặc liên quan đến
                các Điều khoản này, việc bạn sử dụng Trang web hoặc mối quan hệ
                của bạn với The Thanh Coffee hoặc bất kỳ công ty con, công ty mẹ
                hoặc công ty liên kết nào trong quá khứ, hiện tại hoặc tương
                lai, cho dù dựa trên hợp đồng, vi phạm, quy chế, gian lận, trình
                bày sai hoặc bất kỳ lý thuyết pháp lý nào khác (“Tranh chấp”) sẽ
                được giải quyết thông qua Quan toà cá nhân có tính ràng buộc,
                ngoại trừ việc một trong hai chúng tôi có thể đưa Tranh chấp ra
                tòa án xử lý các vụ kiện nhỏ miễn là tranh chấp đó không bị xóa
                bỏ hoặc kháng cáo lên tòa án của thẩm quyền chung. Tranh chấp sẽ
                bao gồm nhưng không giới hạn ở: (a) mọi tranh chấp hoặc khiếu
                nại phát sinh trước khi có các Điều khoản này hoặc bất kỳ Điều
                khoản nào trước đó (bao gồm nhưng không giới hạn ở các khiếu nại
                liên quan đến quảng cáo); (b) bất kỳ tranh chấp hoặc khiếu nại
                nào hiện là đối tượng của vụ kiện tập thể có chủ đích mà bạn
                không phải là thành viên của một tập thể được chứng nhận; và (c)
                mọi tranh chấp hoặc khiếu nại có thể phát sinh sau khi chấm dứt
                các Điều khoản này. Tuy nhiên, tranh chấp không bao gồm những
                bất đồng hoặc khiếu nại liên quan đến bằng sáng chế, bản quyền,
                nhãn hiệu và bí mật thương mại cũng như khiếu nại về vi phạm bản
                quyền hoặc sử dụng trái phép tài sản trí tuệ. Quan toà sẽ quyết
                định tất cả các vấn đề <strong>ngoại trừ</strong> những vấn đề
                sau (do tòa án có thẩm quyền quyết định): (a) các vấn đề được
                dành riêng cho tòa án trong các Điều khoản này; (b) các vấn đề
                liên quan đến phạm vi, hiệu lực và khả năng thực thi của thỏa
                thuận Quan toà, từ bỏ vụ kiện tập thể hoặc bất kỳ điều khoản nào
                trong phần Giải quyết tranh chấp này; và (c) các vấn đề liên
                quan đến khả năng phân xử của bất kỳ Tranh chấp nào. Các Điều
                khoản này và thỏa thuận Quan toà này không ngăn cản bạn đưa
                Tranh chấp ra trước bất kỳ cơ quan chính phủ nào. Bạn và chúng
                tôi đồng ý rằng các Điều khoản này chứng minh một giao dịch
                trong thương mại giữa các tiểu bang và thỏa thuận Quan toà này
                sẽ được giải thích và thực thi theo Đạo luật Quan toà Liên bang
                và luật Quan toà liên bang.
              </li>
              <li>
                <strong>
                  <i>
                    Quy trình giải quyết tranh chấp không chính thức bắt buộc.
                  </i>
                </strong>{" "}
                Bạn và chúng tôi đồng ý hợp tác cùng nhau trong nỗ lực giải
                quyết một cách không chính thức mọi Tranh chấp giữa chúng ta.
                Bên khởi xướng Tranh chấp phải gửi cho bên kia một thông báo
                bằng văn bản về Tranh chấp bao gồm tất cả thông tin này: (a)
                thông tin đủ để xác định bất kỳ giao dịch và tài khoản nào đang
                được đề cập; (b) thông tin liên hệ (bao gồm tên, địa chỉ, số
                điện thoại và địa chỉ email); và (c) mô tả chi tiết về bản chất
                và cơ sở của Tranh chấp cũng như biện pháp giải quyết được yêu
                cầu, bao gồm cả cách tính toán giải pháp đó. Thông báo phải có
                chữ ký cá nhân của bên khởi xướng Tranh chấp (và luật sư của họ,
                nếu có đại diện). Nếu bạn có Tranh chấp với chúng tôi, bạn phải
                gửi thông báo này đến bộ phận Dịch vụ khách hàng của chúng tôi
                bằng liên kết thích hợp tại
                www.dichvukhachhang.thanhcoffee.com/web. Nếu chúng tôi có Tranh
                chấp với bạn, chúng tôi sẽ gửi thông báo này đến thông tin liên
                hệ gần đây nhất mà chúng tôi có cho bạn. Trong thời hạn 60 ngày
                kể từ khi nhận được thông báo hoàn chỉnh (có thể được gia hạn
                theo thỏa thuận của các bên), bạn và chúng tôi đồng ý đàm phán
                một cách thiện chí trong nỗ lực giải quyết Tranh chấp một cách
                không chính thức. Bên nhận được thông báo có thể yêu cầu một
                cuộc họp giải quyết qua điện thoại để hỗ trợ giải quyết Tranh
                chấp. Nếu một cuộc họp như vậy được yêu cầu, bạn và đại diện của
                The Thanh Coffee sẽ đích thân tham dự (với luật sư, nếu được đại
                diện). Hội nghị sẽ được lên lịch vào thời gian thuận tiện cho cả
                hai bên, có thể nằm ngoài thời hạn 60 ngày. Việc hoàn thành Quy
                trình giải quyết tranh chấp không chính thức bắt buộc này (“Quy
                trình”) là điều kiện tiên quyết để bắt đầu khiếu nại tại Quan
                toà. Nếu có vấn đề về tính đầy đủ của thông báo hoặc việc tuân
                thủ Quy trình này thì vấn đề đó có thể được nêu ra và quyết định
                bởi tòa án có thẩm quyền tại cuộc bầu cử của một trong hai bên
                và mọi hoạt động phân xử Quan toà sẽ được hoãn lại. Tòa án sẽ có
                thẩm quyền thực thi điều kiện này trước khi phân xử bằng Quan
                toà, bao gồm quyền ra lệnh ra lệnh khởi kiện hoặc truy tố Quan
                toà và đánh giá hoặc thu phí Quan toà. Không có nội dung nào
                trong đoạn này giới hạn quyền của một bên trong việc yêu cầu bồi
                thường thiệt hại do không tuân thủ Quy trình này trong Quan toà.
                Tất cả các khoảng thời gian giới hạn áp dụng (bao gồm cả thời
                hiệu) sẽ được tính kể từ ngày nhận được thông báo đầy đủ cho đến
                khi kết thúc Quy trình này. Bạn hoặc chúng tôi có thể bắt đầu
                phân xử bằng Quan toà nếu Tranh chấp không được giải quyết thông
                qua Quy trình này.
              </li>
              <li>
                <strong>
                  <i>Thủ tục Quan toà.</i>
                </strong>
                Việc phân xử bất kỳ Tranh chấp nào sẽ được quản lý và tiến hành
                theo các quy tắc của Pháp luật Việt Nam.
              </li>
            </ul>
            <Box>
              Yêu cầu phân xử bằng Quan toà phải kèm theo chứng nhận tuân thủ
              Quy trình và có chữ ký cá nhân của bên khởi xướng phân xử bằng
              Quan toà (và luật sư, nếu có đại diện). Bằng cách gửi yêu cầu phân
              xử bằng Quan toà, bên và luật sư thể hiện rằng, giống như trước
              tòa, rằng họ đang tuân thủ các yêu cầu của Quy tắc tố tụng dân sự
              liên bang 11(b). Quan toà viên được ủy quyền áp đặt bất kỳ biện
              pháp trừng phạt nào theo Quy tắc tố tụng dân sự liên bang 11 đối
              với các bên được đại diện và luật sư của họ. Bạn có thể chọn tiến
              hành phân xử bằng Quan toà qua điện thoại, video hoặc xét xử trực
              tiếp hoặc thông qua gửi bằng văn bản, ngoại trừ bất kỳ Tranh chấp
              nào đòi hỏi 500,000,000đ trở lên hoặc biện pháp cứu trợ theo lệnh,
              sẽ có phiên xét xử trực tiếp hoặc video trừ khi các bên đồng ý nếu
              không thì. Bạn và chúng tôi có quyền yêu cầu Quan toà tham vấn về
              bất kỳ vấn đề nào. Bạn và đại diện của The Thanh Coffee sẽ đích
              thân xuất hiện tại bất kỳ phiên xét xử nào (cùng với luật sư, nếu
              được đại diện). Mọi phiên xét xử trực tiếp sẽ được tổ chức tại
              quận hoặc giáo xứ nơi bạn cư trú hoặc tại một địa điểm khác được
              hai bên thống nhất. Quan toà có thể ra phán quyết trên cơ sở cá
              nhân bất kỳ biện pháp khắc phục nào có thể có tại tòa án, bao gồm
              cả biện pháp khẩn cấp theo lệnh hoặc tuyên bố chỉ có lợi cho bên
              cá nhân đang tìm kiếm biện pháp khắc phục và chỉ trong phạm vi cần
              thiết để cung cấp biện pháp khắc phục được bảo đảm theo yêu cầu cá
              nhân của bên đó. Trong phạm vi tối đa được luật hiện hành cho
              phép, bạn và chúng tôi đồng ý rằng mỗi bên chỉ có thể đưa ra khiếu
              nại chống lại bên kia với tư cách cá nhân của bạn hoặc của chúng
              tôi chứ không phải với tư cách là nguyên đơn hoặc thành viên tập
              thể trong bất kỳ tập thể, tập thể, hợp nhất, tổng chưởng lý tư
              nhân nào, hoặc thủ tục đại diện. Hơn nữa, trừ khi cả bạn và chúng
              tôi đều đồng ý khác, Quan toà không được hợp nhất các khiếu nại
              của nhiều người và không được chủ trì bất kỳ hình thức tố tụng tập
              thể, tập thể, hợp nhất, tổng chưởng lý riêng hoặc đại diện nào.
              Quan toà phải tuân theo và thực thi các Điều khoản này giống như
              tòa án. Nếu, sau khi hết tất cả các kháng cáo, bất kỳ lệnh cấm nào
              đối với biện pháp khẩn cấp theo lệnh hoặc tuyên bố không mang tính
              cá nhân và tập thể, tập thể, hợp nhất, tổng chưởng lý riêng hoặc
              thủ tục đại diện được thấy là không thể thi hành được đối với một
              khiếu nại hoặc yêu cầu bồi thường cụ thể ( chẳng hạn như yêu cầu
              biện pháp khẩn cấp theo lệnh công cộng), thì khiếu nại hoặc yêu
              cầu bồi thường đó sẽ được quyết định bởi tòa án có thẩm quyền, sau
              khi tất cả các khiếu nại và yêu cầu bồi thường khác được phân xử
              bằng Quan toà.
            </Box>
            <br />
            <Box>
              Quan toà sẽ đưa ra quyết định bằng văn bản có lý do đủ để giải
              thích những phát hiện và kết luận quan trọng. Quan toà sẽ áp dụng
              các điều khoản chuyển đổi chi phí của Quy tắc tố tụng dân sự liên
              bang 68 sau khi đưa ra phán quyết. Phán quyết về bất kỳ phán quyết
              Quan toà nào có thể được đưa ra tại bất kỳ tòa án nào có thẩm
              quyền xét xử, ngoại trừ phán quyết đã được thi hành có thể không
              được đưa ra. Phán quyết sẽ không có hiệu lực loại trừ trong bất kỳ
              Quan toà hoặc thủ tục tố tụng nào khác mà bạn không phải là một
              bên được nêu tên.
            </Box>
            <ul>
              <li>
                <strong>
                  <i>Chi phí Quan toà.</i>
                </strong>
                Việc thanh toán phí Quan toà sẽ được điều chỉnh bởi Quy tắc Pháp
                luật Việt Nam và biểu phí. Bạn và chúng tôi đồng ý rằng các bên
                có chung lợi ích trong việc giảm chi phí và tăng hiệu quả liên
                quan đến Quan toà. Do đó, bạn hoặc chúng tôi có thể chọn tham
                gia với các điều khoản được quy định theo Pháp luật Việt Nam về
                phí Quan toà, đồng thời bạn và chúng tôi đồng ý rằng các bên (và
                luật sư, nếu được đại diện) sẽ hợp tác cùng nhau một cách thiện
                chí để đảm bảo rằng Quan toà vẫn mang lại hiệu quả về mặt chi
                phí cho tất cả các bên.
              </li>
              <br />
              <li>
                <strong>
                  <i>Thủ tục bổ sung cho nhiều hồ sơ vụ án.</i>
                </strong>
                Bạn và chúng tôi đồng ý rằng các Thủ tục bổ sung cho việc nộp
                nhiều vụ việc (ngoài các điều khoản khác của thỏa thuận Quan toà
                này) sẽ được áp dụng nếu bạn chọn tham gia vào việc nộp nhiều vụ
                việc. Nếu từ 25 Tranh chấp tương tự trở lên (bao gồm cả tranh
                chấp của bạn) được khẳng định chống lại The Thanh Coffee bởi
                cùng một luật sư hoặc luật sư phối hợp hoặc được phối hợp theo
                cách khác (“Gửi nhiều vụ việc”) thì việc giải quyết Tranh chấp
                của bạn có thể bị trì hoãn và cuối cùng sẽ được đưa ra tòa. Các
                bên đồng ý rằng như một phần của các thủ tục này, luật sư của họ
                sẽ gặp và trao đổi một cách thiện chí nhằm nỗ lực giải quyết
                Tranh chấp, hợp lý hóa các thủ tục, giải quyết việc trao đổi
                thông tin, sửa đổi số lượng Tranh chấp cần xét xử và bảo vệ
                quyền lợi của các bên. và nguồn lực của Pháp luật Việt Nam.
              </li>
            </ul>
            <Box>
              Nếu khiếu nại của bạn là một phần của Nộp hồ sơ nhiều vụ việc thì
              mọi khoảng thời gian giới hạn áp dụng (bao gồm cả thời hiệu) sẽ
              được tính cho Tranh chấp của bạn kể từ thời điểm Tranh chấp của
              bạn được gửi lần đầu tiên tới Pháp luật Việt Nam cho đến khi Tranh
              chấp của bạn được chọn để tiếp tục như một phần của tiến hành thủ
              tục hoặc được giải quyết, rút ​​lui, giải quyết theo cách khác
              hoặc từ chối tham gia Quan toà theo quy định này.
            </Box>
            <br />
            <Box>
              <strong>GIAI ĐOẠN MỘT:</strong>Nếu có ít nhất 100 Tranh chấp được
              gửi như một phần của Gửi hồ sơ nhiều vụ việc, luật sư của nguyên
              đơn và luật sư của The Thanh Coffee sẽ chọn 50 Tranh chấp để gửi
              và tiến hành như các vụ việc trong các phân xử Quan toà riêng lẻ
              như một phần của quy trình được dàn dựng ban đầu này. Số lượng
              Tranh chấp được lựa chọn để tiến hành trong Giai đoạn một có thể
              tăng lên theo thỏa thuận của luật sư giữa các bên (và nếu có ít
              hơn 100 Tranh chấp, tất cả sẽ được tiến hành riêng lẻ trong Giai
              đoạn một). Mỗi trường hợp trong số 100 (hoặc ít hơn) sẽ được giao
              cho một Quan toà viên khác và tiến hành riêng lẻ. Nếu vụ việc được
              rút lại trước khi ban hành phán quyết Quan toà, một khiếu nại khác
              sẽ được chọn để tiến hành như một phần của Giai đoạn một. Các
              Tranh chấp còn lại sẽ không được đệ trình hoặc coi là được đưa ra
              Quan toà cũng như sẽ không được đánh giá hoặc thu bất kỳ khoản phí
              Quan toà nào liên quan đến các khiếu nại đó. Sau loạt thủ tục tố
              tụng ban đầu này, luật sư của các bên sẽ tham gia vào phiên hòa
              giải toàn cầu với thẩm phán tòa án liên bang hoặc tiểu bang đã
              nghỉ hưu được luật sư cùng lựa chọn trong nỗ lực giải quyết các
              Tranh chấp còn lại (như được thông báo trong quá trình xét xử các
              vụ việc ở Giai đoạn Một) , và The Thanh Coffee sẽ thanh toán phí
              hòa giải.
            </Box>
            <br />
            <Box>
              <strong>GIAI ĐOẠN HAI: </strong>Nếu các Tranh chấp còn lại chưa
              được giải quyết khi kết thúc Giai đoạn Một, luật sư cho các nguyên
              đơn và luật sư của The Thanh Coffee sẽ chọn 100 Tranh chấp cho mỗi
              bên để đưa ra và tiến hành như các vụ việc trong phân xử Quan toà
              riêng lẻ như một phần của giai đoạn thứ hai quá trình. Số lượng
              Tranh chấp được chọn để tiến hành như một phần của quy trình giai
              đoạn thứ hai này có thể tăng lên theo thỏa thuận của luật sư giữa
              các bên (và nếu có ít hơn 200 Tranh chấp, tất cả sẽ được tiến hành
              riêng lẻ trong Giai đoạn Hai). Không quá năm trường hợp có thể
              được giao cho một Quan toà duy nhất để tiến hành riêng lẻ. Nếu vụ
              việc được rút lại trước khi ban hành phán quyết Quan toà, một
              khiếu nại khác sẽ được chọn để tiến hành như một phần của Giai
              đoạn Hai. Các Tranh chấp còn lại sẽ không được đệ trình hoặc coi
              là được đưa ra Quan toà cũng như sẽ không được đánh giá hoặc thu
              bất kỳ khoản phí Quan toà nào liên quan đến các khiếu nại đó. Sau
              loạt thủ tục tố tụng được tổ chức lần thứ hai này, các bên sẽ tham
              gia vào một phiên hòa giải toàn cầu về tất cả các Tranh chấp còn
              lại với một thẩm phán tòa án liên bang hoặc tiểu bang đã nghỉ hưu
              do luật sư cùng lựa chọn nhằm nỗ lực giải quyết các Tranh chấp còn
              lại (như được thông báo trong quá trình xét xử các vụ việc trong
              Giai đoạn Một và Hai), và The Thanh Coffee sẽ thanh toán phí hòa
              giải.
            </Box>
            <br />
            <Box>
              Sau khi hoàn tất quá trình hòa giải được nêu trong Giai đoạn Hai,
              mỗi Tranh chấp còn lại (nếu có) không được giải quyết hoặc không
              được rút lại sẽ được chọn không tham gia phân xử bằng Quan toà và
              có thể tiến hành tại tòa án có thẩm quyền xét xử phù hợp với phần
              còn lại của Điều khoản. Bất kể những điều đã nói ở trên, luật sư
              của các bên có thể cùng đồng ý bằng văn bản để tiến hành xét xử
              một số hoặc tất cả các Tranh chấp còn lại bằng các thủ tục Quan
              toà riêng lẻ phù hợp với quy trình được nêu trong Giai đoạn Hai
              (ngoại trừ các Tranh chấp sẽ được chọn ngẫu nhiên và hòa giải sẽ
              được tự chọn). theo thỏa thuận của luật sư) hoặc thông qua một quy
              trình khác mà các bên cùng đồng ý. Tòa án có thẩm quyền sẽ có thẩm
              quyền thi hành Thủ tục bổ sung đối với việc nộp nhiều vụ việc, bao
              gồm quyền ra lệnh ra lệnh nộp đơn hoặc truy tố Quan toà và đánh
              giá hoặc thu phí Quan toà.
            </Box>
            <br />
            <Box>
              Quy định về Thủ tục bổ sung đối với việc nộp nhiều vụ việc và mỗi
              yêu cầu trong đó là những phần thiết yếu của thỏa thuận Quan toà
              này. Nếu, sau khi hết tất cả các kháng nghị, tòa án có thẩm quyền
              quyết định rằng Thủ tục bổ sung cho việc nộp nhiều vụ việc áp dụng
              cho Tranh chấp của bạn và không thể thi hành được thì Tranh chấp
              của bạn sẽ không được tiến hành bằng Quan toà và sẽ chỉ được tiến
              hành tại tòa án có thẩm quyền phù hợp với phần còn lại của Điều
              khoản.
            </Box>
            <ul>
              <li>
                <strong>
                  <i>
                    Những thay đổi trong tương lai đối với Thỏa thuận Quan toà.
                  </i>
                </strong>
                Nếu chúng tôi thực hiện bất kỳ thay đổi nào trong tương lai đối
                với thỏa thuận Quan toà này (ngoài thay đổi về thông tin liên hệ
                của chúng tôi), bạn có thể từ chối mọi thay đổi đó bằng cách gửi
                thông báo bằng văn bản có chữ ký cá nhân của bạn đến địa chỉ sau
                trong vòng 30 ngày kể từ ngày thay đổi:
                <br />
                <br />
                Công ty TNHH SaintGiong
                <br />
                Bộ phận Pháp chế và Chế tài Doanh nghiệp
                <br />
                15 Phạm Hùng, Phường Mỹ Đình 2, Quận Nam Từ Liêm, TP Hà Nội
                <br />
                Người nhận: Phòng Pháp chế
              </li>
            </ul>
            <Box>
              Thông báo bằng văn bản như vậy hoàn toàn không cấu thành sự từ
              chối tham gia Quan toà. Bằng cách từ chối mọi thay đổi trong tương
              lai, bạn đồng ý rằng bạn sẽ phân xử mọi Tranh chấp giữa bạn và The
              Thanh Coffee theo phiên bản thỏa thuận Quan toà này.
            </Box>
            <h4>
              Miễn trừ vụ kiện tập thể và Miễn trừ xét xử của bồi thẩm đoàn
            </h4>
            <ul>
              <li>
                Bạn và chúng tôi đều đồng ý rằng bất kỳ thủ tục tố tụng nào, dù
                là tại Quan toà hay tại tòa án, sẽ chỉ được tiến hành trên cơ sở
                cá nhân chứ không phải theo một vụ kiện tập thể, tập thể, hợp
                nhất, tổng chưởng lý riêng hoặc hành động đại diện. Bạn và chúng
                tôi đồng ý từ bỏ mọi quyền đưa ra hoặc tham gia vào hành động đó
                tại Quan toà hoặc tại tòa án trong phạm vi tối đa được luật pháp
                hiện hành cho phép. Bất kể những điều đã nói ở trên, các bên vẫn
                có quyền tham gia vào một cuộc dàn xếp toàn tập thể.
              </li>
              <li>
                Trong phạm vi tối đa được pháp luật hiện hành cho phép, bạn và
                chúng tôi từ bỏ quyền được xét xử bởi bồi thẩm đoàn.
              </li>
            </ul>
            <h4>Luật chính quyền và quyền tài phán</h4>
            <Box>
              Các Điều khoản này và việc sử dụng Trang web này được điều chỉnh
              bởi luật pháp của Việt Nam, bất kể các quy tắc xung đột pháp luật
              pháp Việt Nam sẽ không được chấp nhận. Nếu thỏa thuận Quan toà
              được coi là không thể thi hành hoặc vô hiệu hoặc tranh chấp giữa
              các bên không phải chịu sự phân xử của Quan toà, thì Người dùng
              đồng ý không thể hủy ngang với thẩm quyền tài phán độc quyền của
              tòa án Việt Nam, vì các mục đích của bất kỳ hành động pháp lý nào
              phát sinh từ hoặc liên quan đến việc sử dụng Trang web hoặc các
              Điều khoản này và từ bỏ mọi phản đối về quyền tài phán cá nhân
              hoặc về việc đặt địa điểm tại các tòa án đó do: (a) diễn đàn bất
              tiện hoặc (b) bất kỳ cơ sở khác hoặc bất kỳ quyền nào để tìm cách
              chuyển hoặc thay đổi địa điểm thực hiện bất kỳ hành động nào như
              vậy sang tòa án khác.
            </Box>
            <h4>Chấm dứt</h4>
            <Box>
              Bất kể bất kỳ Điều khoản nào trong số này, The Thanh Coffee có
              quyền, mà không cần thông báo và theo quyết định riêng của mình,
              chấm dứt giấy phép sử dụng Trang web của bạn và chặn hoặc ngăn
              chặn việc bạn truy cập và sử dụng Trang web trong tương lai. Việc
              The Thanh Coffee không thực hiện hoặc trì hoãn thực hiện những
              hành động như vậy không cấu thành sự từ bỏ quyền thực thi các Điều
              khoản này.
            </Box>
            <h4>Thay đổi</h4>
            <Box>
              The Thanh Coffee có quyền thay đổi hoặc sửa đổi các Điều khoản này
              hoặc bất kỳ chính sách nào khác của The Thanh Coffee liên quan đến
              việc sử dụng Trang web bất cứ lúc nào và theo quyết định riêng của
              mình bằng cách đăng các bản sửa đổi trên Trang web. Việc tiếp tục
              sử dụng Trang web sau những thay đổi hoặc sửa đổi đối với Điều
              khoản hoặc các chính sách khác của The Thanh Coffee sẽ cấu thành
              sự chấp nhận những thay đổi hoặc sửa đổi đó.
            </Box>
            <h4>Trường hợp bất khả kháng</h4>
            <Box>
              Trừ khi có quy định khác ở đây, nếu bất kỳ điều khoản nào trong
              các Điều khoản này bị coi là bất hợp pháp, vô hiệu hoặc không thể
              thi hành vì bất kỳ lý do nào thì điều khoản đó sẽ được coi là có
              thể tách rời khỏi các Điều khoản này và sẽ không ảnh hưởng đến
              hiệu lực và khả năng thực thi của mọi điều khoản còn lại.
            </Box>
            <br />
            <Box>
              Ngoài các điều khoản khác trong đây, theo điều khoản của chúng,
              vẫn có hiệu lực sau khi Thỏa thuận này chấm dứt hoặc hết hạn, các
              phần sau đây sẽ vẫn có hiệu lực khi chấm dứt các Điều khoản này:
              (a) Bồi thường; (b) Bảo đảm; Tuyên bố miễn trừ trách nhiệm; (c)
              Không chịu trách nhiệm pháp lý; (d) Giải quyết tranh chấp (bao gồm
              Thỏa thuận Quan toà; Miễn trừ vụ kiện tập thể; Miễn trừ xét xử của
              bồi thẩm đoàn); và (e) Luật điều chỉnh và thẩm quyền.
            </Box>
            <br />
            <br />
            <br />
            <br />
            <h4>Liên hệ</h4>
            <Box>
              Trừ khi có quy định khác trong các Điều khoản này, mọi câu hỏi,
              khiếu nại hoặc khiếu nại liên quan đến Trang web phải được chuyển
              tới:
            </Box>
            <br />
            <Box>
              Bộ phận Chăm sóc Khách hàng của Công ty Cà phê The Thanh Coffee(
              thongtin@thanhcoffee.com )
            </Box>
            <Box>
              15 Phạm Hùng, Phường Mỹ Đình 2, Quận Nam Từ Liêm, TP Hà Nội{" "}
            </Box>
          </Box>
        </Box>
      </Layout.SecondaryBg>
      <Footer />
    </Box>
  );
};

export default Desktop;
