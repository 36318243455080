import { Box } from "@mui/material";
import React from "react";
import { styles } from "../../../styles";
const NewsFilterIcon = () => {
  return (
    <Box sx={{ ...styles.centerFlex }}>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1633 20.673H27.4617L22.2909 13.8222L17.6897 19.5916L14.7117 16.0577L11.1633 20.673ZM9.21656 26.875C8.35344 26.875 7.63281 26.5859 7.05469 26.0078C6.47656 25.4297 6.1875 24.7091 6.1875 23.8459V3.65406C6.1875 2.79094 6.47656 2.07031 7.05469 1.49219C7.63281 0.914062 8.35344 0.625 9.21656 0.625H29.4084C30.2716 0.625 30.9922 0.914062 31.5703 1.49219C32.1484 2.07031 32.4375 2.79094 32.4375 3.65406V23.8459C32.4375 24.7091 32.1484 25.4297 31.5703 26.0078C30.9922 26.5859 30.2716 26.875 29.4084 26.875H9.21656ZM9.21656 25H29.4084C29.6972 25 29.9617 24.8798 30.202 24.6395C30.4423 24.3992 30.5625 24.1347 30.5625 23.8459V3.65406C30.5625 3.36531 30.4423 3.10078 30.202 2.86047C29.9617 2.62016 29.6972 2.5 29.4084 2.5H9.21656C8.92781 2.5 8.66328 2.62016 8.42297 2.86047C8.18266 3.10078 8.0625 3.36531 8.0625 3.65406V23.8459C8.0625 24.1347 8.18266 24.3992 8.42297 24.6395C8.66328 24.8798 8.92781 25 9.21656 25ZM3.59156 32.5C2.72844 32.5 2.00781 32.2109 1.42969 31.6328C0.851562 31.0547 0.5625 30.3342 0.5625 29.4714V7.40406H2.4375V29.4714C2.4375 29.7598 2.55766 30.0242 2.79797 30.2645C3.03828 30.5048 3.30281 30.625 3.59156 30.625H25.6584V32.5H3.59156Z"
          fill="#1C1B1F"
        />
      </svg>
    </Box>
  );
};

export default NewsFilterIcon;
